package com.ecosave.watch.portal.helpers.billing

import com.ecosave.watch.portal.helpers.common.generateParamsFromOptions
import com.ecosave.watch.portal.models.billing.ElectricBillValidationState
import com.ecosave.watch.portal.models.billing.NaturalGasBillValidationState
import com.ecosave.watch.portal.models.billing.SolarBillValidationState
import com.ecosave.watch.portal.models.billing.SteamBillValidationState
import com.ecosave.watch.portal.models.billing.UtilityAccount
import com.ecosave.watch.portal.models.billing.UtilityAccountFilterParamsDataClass
import com.ecosave.watch.portal.models.billing.UtilityAccountFiltersDataClass
import com.ecosave.watch.portal.models.billing.UtilityAccountRegistration
import com.ecosave.watch.portal.models.billing.UtilityAccountState
import com.ecosave.watch.portal.models.billing.UtilityBill
import com.ecosave.watch.portal.models.billing.UtilityBillSummary
import com.ecosave.watch.portal.models.billing.UtilityBillSummaryFilterParams
import com.ecosave.watch.portal.models.billing.UtilityBillSummaryTableFilter
import com.ecosave.watch.portal.models.billing.UtilityBillValidationState
import com.ecosave.watch.portal.models.billing.WaterBillValidationState
import com.ecosave.watch.portal.models.billing.utilitybillstate.ElectricAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.NaturalGasAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SolarBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.SteamBillAdditional
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import com.ecosave.watch.portal.models.billing.utilitybillstate.WaterBillAdditional
import kotlin.js.Date
import kotlinx.coroutines.MainScope
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import react.StateSetter

val mainScope = MainScope()

fun formatBillingValues(value: Float?, unit: String?): String {
    return if (value == null) {
        ""
    } else if (unit == null) {
        "$ ${value.asDynamic().toLocaleString("en-US")}"
    } else {
        "${value.asDynamic().toLocaleString("en-US")} $unit"
    }
}

fun getUtilityTypeDescription(value: String): String {
    return when (UtilityType.valueOf(value)) {
        UtilityType.ELECTRIC -> UtilityType.ELECTRIC.type
        UtilityType.NATURAL_GAS -> UtilityType.NATURAL_GAS.type
        UtilityType.WATER -> UtilityType.WATER.type
        UtilityType.STEAM -> UtilityType.STEAM.type
        UtilityType.HOT_WATER -> UtilityType.HOT_WATER.type
        UtilityType.CHILLED_WATER -> UtilityType.CHILLED_WATER.type
        UtilityType.SOLAR -> UtilityType.SOLAR.type
    }
}

fun formatBillingLabels(billingHeader: BillingHeaders, appendDollar: Boolean): String {
    val label = billingHeader.header
    return if (appendDollar) {
        "$label ($)"
    } else {
        label
    }
}

fun formatWaterBillingLabels(billingHeader: BillingWaterHeaders, appendDollar: Boolean): String {
    val label = billingHeader.header
    return if (appendDollar) {
        "$label ($)"
    } else {
        label
    }
}

fun formatSteamBillingLabels(billingHeader: BillingSteamHeaders, appendDollar: Boolean): String {
    val label = billingHeader.header
    return if (appendDollar) {
        "$label ($)"
    } else {
        label
    }
}

fun formatSolarBillingLabels(billingHeader: BillingSolarHeaders, appendDollar: Boolean): String {
    val label = billingHeader.header
    return if (appendDollar) {
        "$label ($)"
    } else {
        label
    }
}

fun getDigitsCountBeforeDecimal(value: String): Int {
    val list: List<String> = value.split('.')
    return list[0].length
}

fun differenceBetweenBillStartAndEndDate(startDate: String, endDate: String): Int {
    val startDateObj = Date(startDate)
    val endDateObj = Date(endDate)
    val diff = endDateObj.getTime() - startDateObj.getTime()
    val days = diff / 86400000
    return days.toInt()
}

fun clearBillStartAndEndDate(state: UtilityBillState, setState: StateSetter<UtilityBillState>) {
    setState(
        state.copy(
            billStartDate = "",
            billEndDate = ""
        )
    )
}

fun getUtilityBillSummaryFilterParams(filters: UtilityBillSummaryTableFilter): UtilityBillSummaryFilterParams {
    var params = ""
    var counter = 0
    if (filters.billStartDate.isNotEmpty()) {
        params += "&billStartDate=${filters.billStartDate}"
        counter += 1
    }
    if (filters.billEndDate.isNotEmpty()) {
        params += "&billEndDate=${filters.billEndDate}"
        counter += 1
    }
    if (filters.utilityType.isNotEmpty()) {
        params += "&utilityTypes=${generateParamsFromOptions(filters.utilityType)}"
        counter += 1
    }
    if (filters.accountNumber.isNotEmpty()) {
        params += "&accountNumbers=${generateParamsFromOptions(filters.accountNumber)}"
        counter += 1
    }
    if (filters.serviceName.isNotEmpty()) {
        params += "&serviceNames=${generateParamsFromOptions(filters.serviceName)}"
        counter += 1
    }
    return UtilityBillSummaryFilterParams(params, counter)
}

fun getUtilityBillIndex(utilityBillList: List<UtilityBillSummary>, utilityBillId: Int): Int {
    val index = utilityBillList.indexOf(utilityBillList.find {
        it.utilityBill.utilityBillId == utilityBillId
    })
    return index
}

fun populateBillState(utilityBill: UtilityBill): UtilityBillState {
    return UtilityBillState(
        utilityAccountId = utilityBill.utilityAccountId,
        utilityBillId = utilityBill.utilityBillId,
        utilityType = utilityBill.utilityType,
        billStartDate = utilityBill.billStartDate,
        billEndDate = utilityBill.billEndDate,
        totalUsage = utilityBill.totalUsage,
        totalBillCost = utilityBill.totalBillCost,
        billEstimated = utilityBill.billEstimated,
        billAdjusted = utilityBill.billAdjusted,
        totalDeliveryDistribution = utilityBill.totalDeliveryDistribution,
        usageUnit = utilityBill.usageUnit.name,
        demandUnit = utilityBill.demandUnit?.name ?: "",
        electricAdditional = ElectricAdditional(
            totalPeakBilledDemand = utilityBill.electricAdditional.totalPeakBilledDemand,
            peakDemandCharges = utilityBill.electricAdditional.peakDemandCharges,
            onPeakUsage = utilityBill.electricAdditional.onPeakUsage,
            onPeakUsageCharges = utilityBill.electricAdditional.onPeakUsageCharges,
            offPeakUsage = utilityBill.electricAdditional.offPeakUsage,
            offPeakUsageCharges = utilityBill.electricAdditional.offPeakUsageCharges,
            offPeakDemand = utilityBill.electricAdditional.offPeakDemand,
            onPeakDemand = utilityBill.electricAdditional.onPeakDemand
        ),
        naturalGasAdditional = NaturalGasAdditional(
            totalCommoditySupply = utilityBill.naturalGasAdditional.totalCommoditySupply
        ),
        waterBillAdditional = WaterBillAdditional(
            serviceCharges = utilityBill.waterBillAdditional.serviceCharges,
            stormWaterCharges = utilityBill.waterBillAdditional.stormWaterCharges,
            industrialSurcharge = utilityBill.waterBillAdditional.industrialSurcharge,
            flow = utilityBill.waterBillAdditional.flow,
            srf = utilityBill.waterBillAdditional.srf,
            flowUsedForBilling = utilityBill.waterBillAdditional.flowUsedForBilling,
            biochemicalOxygenDemand = utilityBill.waterBillAdditional.biochemicalOxygenDemand,
            bodSurchargeFactor = utilityBill.waterBillAdditional.bodSurchargeFactor,
            bodCharges = utilityBill.waterBillAdditional.bodCharges,
            totalSuspendedSolids = utilityBill.waterBillAdditional.totalSuspendedSolids,
            tssSurchargeFactor = utilityBill.waterBillAdditional.tssSurchargeFactor,
            tssCharges = utilityBill.waterBillAdditional.tssCharges,
            totalSurchargeBill = utilityBill.waterBillAdditional.totalSurchargeBill
        ),
        steamBillAdditional = SteamBillAdditional(
            demandMeasured = utilityBill.steamBillAdditional.demandMeasured,
            demandBilled = utilityBill.steamBillAdditional.demandBilled,
            totalHeatingSteamCharges = utilityBill.steamBillAdditional.totalHeatingSteamCharges,
            heatingDemandCharges = utilityBill.steamBillAdditional.heatingDemandCharges,
            heatingSteamUsage = utilityBill.steamBillAdditional.heatingSteamUsage,
            heatingUsageCharges = utilityBill.steamBillAdditional.heatingUsageCharges,
            coolingSteamUsage = utilityBill.steamBillAdditional.coolingSteamUsage,
            totalCoolingSteamCharges = utilityBill.steamBillAdditional.totalCoolingSteamCharges
        ),
        solarBillAdditional = SolarBillAdditional(
            totalSolarGenerated = utilityBill.solarBillAdditional.totalSolarGenerated,
            totalSolarCost = utilityBill.solarBillAdditional.totalSolarCost,
            solarExported = utilityBill.solarBillAdditional.solarExported,
            solarExportedCredit = utilityBill.solarBillAdditional.solarExportedCredit
        )
    )
}

fun utilityBillStateInitialization(): UtilityBillState {
    return UtilityBillState(
        electricAdditional = ElectricAdditional(),
        naturalGasAdditional = NaturalGasAdditional(),
        waterBillAdditional = WaterBillAdditional(),
        steamBillAdditional = SteamBillAdditional(),
        solarBillAdditional = SolarBillAdditional()
    )
}

fun utilityBillValidationStateInitialization(): UtilityBillValidationState {
    return UtilityBillValidationState(
        electricBillValidationState = ElectricBillValidationState(),
        naturalGasBillValidationState = NaturalGasBillValidationState(),
        waterBillValidationState = WaterBillValidationState(),
        steamBillValidationState = SteamBillValidationState(),
        solarBillValidationState = SolarBillValidationState()
    )
}

fun isEditBillFormValid(
    state: UtilityBillState,
    validation: UtilityBillValidationState,
    setValidation: StateSetter<UtilityBillValidationState>,
    surchargeBillExists: Boolean?,
    isSolarNetMetered: Boolean?,
    solarPPAInPlace: Boolean?,
    coolingSteamUsed: Boolean,
    heatingSteamUsed: Boolean
): Boolean {

    val isValid = when (UtilityType.valueOf(state.utilityType)) {
        UtilityType.ELECTRIC -> isBillingElectricFormValid(state, validation, setValidation)
        UtilityType.NATURAL_GAS -> isBillingNaturalGasFormValid(state, validation, setValidation)
        UtilityType.WATER -> surchargeBillExists?.let { isBillingWaterFormValid(state, validation, setValidation, it) }
        UtilityType.STEAM -> isBillingSteamFormValid(
            state,
            validation,
            setValidation,
            coolingSteamUsed,
            heatingSteamUsed
        )

        UtilityType.HOT_WATER -> isBillingHotOrChilledWaterFormValid(state, validation, setValidation)
        UtilityType.CHILLED_WATER -> isBillingHotOrChilledWaterFormValid(state, validation, setValidation)
        UtilityType.SOLAR ->
            isSolarNetMetered?.let {
                solarPPAInPlace?.let { it1 ->
                    isBillingSolarFormValid(
                        state,
                        validation,
                        setValidation,
                        it,
                        it1
                    )
                }
            }
    }

    return isValid ?: false
}


fun resetAddUtilityAccountForm(
    formState: UtilityAccountState,
    setFormState: StateSetter<UtilityAccountState>,
    utilityType: String,
    isActive: Boolean?,
    solarSystemInUse: Boolean?,
    coolingSteamUsed: Boolean?,
    heatingSteamUsed: Boolean?,
) {
    setFormState(
        formState.copy(
            isActive = isActive,
            solarSystemInUse = solarSystemInUse,
            utilityType = utilityType,
            utilityServiceProviderId = "",
            serviceName = "",
            serviceAddress = "",
            accountNumber = "",
            meterNumber = "",
            solarTotalAnnualGeneration = null,
            solarTotalInstalledCapacity = null,
            solarUsageUnit = "",
            solarCapacityUnit = "",
            solarPPAInPlace = null,
            solarPPAServiceProvider = "",
            solarServiceStartDate = "",
            solarTotalLengthOfPPA = null,
            solarCurrentPPARate = null,
            solarNetMetered = null,
            surchargeBillExists = null,
            coolingSteamUsed = coolingSteamUsed,
            heatingSteamUsed = heatingSteamUsed,
            utilityServiceProviderNameOther = "",
            utilityServiceProviderWebsiteUrl = ""
        )
    )
}

fun getUtilityAccountsFilterParams(filters: UtilityAccountFiltersDataClass): UtilityAccountFilterParamsDataClass {
    var params = ""
    var counter = 0
    if (filters.facility.isNotEmpty()) {
        params += "&facilityIds=${generateParamsFromOptions(filters.facility)}"
        counter += 1
    }
    if (filters.state.isNotEmpty()) {
        params += "&states=${generateParamsFromOptions(filters.state)}"
        counter += 1
    }
    if (filters.zipcode.isNotEmpty()) {
        params += "&zipcodes=${generateParamsFromOptions(filters.zipcode)}"
        counter += 1
    }
    if (filters.city.isNotEmpty()) {
        params += "&cities=${generateParamsFromOptions(filters.city)}"
        counter += 1
    }
    if (filters.street.isNotEmpty()) {
        params += "&streets=${generateParamsFromOptions(filters.street)}"
        counter += 1
    }

    return UtilityAccountFilterParamsDataClass(params, counter)
}

fun createUtilityAccountRequestBody(state: UtilityAccountState): UtilityAccountRegistration {
    return UtilityAccountRegistration(
        utilityAccount = UtilityAccount(
            utilityAccountId = state.utilityAccountId,
            facilityId = state.facilityId!!,
            utilityType = state.utilityType,
            serviceName = state.serviceName,
            accountNumber = state.accountNumber.ifBlank { null },
            meterNumber = state.meterNumber.ifBlank { null },
            serviceAddress = state.serviceAddress,
            utilityServiceProviderId = state.utilityServiceProviderId.toIntOrNull(),
            utilityServiceProviderNameOther = state.utilityServiceProviderNameOther.ifBlank { null },
            utilityServiceProviderWebsiteUrl = state.utilityServiceProviderWebsiteUrl.ifBlank { null },
            surchargeBillExists = state.surchargeBillExists,
            coolingSteamUsed = state.coolingSteamUsed,
            heatingSteamUsed = state.heatingSteamUsed,
            isActive = state.isActive,
            solarTotalInstalledCapacity = state.solarTotalInstalledCapacity,
            solarCapacityUnit = state.solarCapacityUnit.ifBlank { null },
            solarTotalAnnualGeneration = state.solarTotalAnnualGeneration,
            solarUsageUnit = state.solarUsageUnit.ifBlank { null },
            solarPPAInPlace = state.solarPPAInPlace,
            solarPPAServiceProvider = state.solarPPAServiceProvider.ifBlank { null },
            solarServiceStartDate = state.solarServiceStartDate.ifBlank { null },
            solarTotalLengthOfPPA = state.solarTotalLengthOfPPA,
            solarCurrentPPARate = state.solarCurrentPPARate,
            solarNetMetered = state.solarNetMetered,
            solarSystemInUse = state.solarSystemInUse,
            collectionMethod = state.collectionMethod,
            energyStarMeterId = state.energyStarMeterId?.ifBlank { null }
        ),
        userId = state.userId,
        password = state.password,
        historyRequired = state.historyRequired
    )
}

fun isEcosaveWatchAutomaticDataCollectionSubscriptionCancelled(
    collectionMethod: UtilityDataCollectionMethod,
    cancellationEffectiveOn: LocalDateTime,
): Boolean {
    return (collectionMethod != UtilityDataCollectionMethod.ECOSAVE_WATCH && Clock.System.now()
        .toLocalDateTime(TimeZone.UTC) > cancellationEffectiveOn)
}

fun isHistoryAlreadyRequested(historyRequestedOn: String?): Boolean {
    return historyRequestedOn != null
}

fun getUtilityServiceProviderName(utilityServiceProviderName: String?, utilityServiceProviderNameOther: String?): String {
    return when (utilityServiceProviderName) {
        "Other" -> {
            utilityServiceProviderNameOther ?: ""
        }

        null -> {
            "NA"
        }

        else -> {
            utilityServiceProviderName
        }
    }
}