package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.create
import web.cssom.px

external interface FacilitySelectionComponentProps : Props {
    var name: String
    var options: Array<AutoCompleteOption>
    var value: AutoCompleteOption?
    var onSelect: (AutoCompleteOption) -> Unit
    var fullWidth: Boolean
    var label: String
}

val FacilitySelectionComponent = FC<FacilitySelectionComponentProps> { props ->
    @Suppress("UPPER_BOUND_VIOLATED")
    (Autocomplete<AutocompleteProps<AutoCompleteOption>> {
        id = props.name
        if (!props.fullWidth) {
            sx {
                width = 250.px
            }
        }
        options = props.options
        defaultValue = props.value
        value = props.value
        disableClearable = true
        onChange = { _, _, reason, details ->
            details?.let { props.onSelect(it.option) }
        }
        size = "small"
        renderInput = { params ->
            TextField.create {
                label = ReactNode(props.label)
                +params
            }
        }
    })
}