package com.ecosave.watch.portal.services.billing

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.common.getAccessTokenFromLocalStorage
import com.ecosave.watch.portal.models.billing.*
import com.ecosave.watch.portal.models.common.JsonResponse
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import js.core.jso
import js.promise.await
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic
import org.w3c.fetch.RequestInit
import web.buffer.Blob
import web.buffer.BlobPropertyBag
import web.file.File
import web.http.FormData
import kotlin.js.json

suspend fun getFacilitiesForAccountSummary(params: String = ""): List<FacilityAccountSummary>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/utility-account/get-by-company$params")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return null
}

suspend fun createUtilityAccount(
    utilityAccount: UtilityAccountRegistration,
): UtilityAccountApiResponse {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/utility-account") {
            setBody(utilityAccount)
        }
        when (response.status) {
            HttpStatusCode.Created -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = response.body(),
                    statusCode = HttpStatusCode.Created
                )
            }

            HttpStatusCode.NotFound -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.NotFound
                )
            }

            HttpStatusCode.Forbidden -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.Forbidden
                )
            }

            HttpStatusCode.ServiceUnavailable -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.ServiceUnavailable
                )
            }
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return UtilityAccountApiResponse(
        utilityAccountSummary = null,
        statusCode = null
    )
}

suspend fun updateUtilityAccount(
    utilityAccount: UtilityAccountRegistration,
): UtilityAccountApiResponse {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/utility-account") {
            setBody(utilityAccount)
        }
        when (response.status) {
            HttpStatusCode.OK -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = response.body(),
                    statusCode = HttpStatusCode.OK
                )
            }

            HttpStatusCode.NotFound -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.NotFound
                )
            }

            HttpStatusCode.Forbidden -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.Forbidden
                )
            }

            HttpStatusCode.ServiceUnavailable -> {
                return UtilityAccountApiResponse(
                    utilityAccountSummary = null,
                    statusCode = HttpStatusCode.ServiceUnavailable
                )
            }
        }
    } catch (e: dynamic) {
        console.log("Error: ", e)
    }
    return UtilityAccountApiResponse(
        utilityAccountSummary = null,
        statusCode = null
    )
}

suspend fun getUtilityServiceProviders(): List<UtilityServiceProviderSummary> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/utility-account/utility-providers")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return emptyList()
}

suspend fun getFiltersValue(): UtilityAccountFiltersResponse {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/utility-account/filters")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return UtilityAccountFiltersResponse()
}

suspend fun getUtilityAccountsByFacilityId(facilityId: String): List<UtilityAccountOptionsResponse> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/utility-account/account-option?facilityId=$facilityId")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return emptyList()
}

@OptIn(ExperimentalSerializationApi::class)
suspend fun uploadBills(file: File, utilityAccountId: Int): BillsImportResponse {
    try {
        val accessToken = getAccessTokenFromLocalStorage()
        val formData = FormData()

        val buffer = file.arrayBuffer().await()
        val options: BlobPropertyBag = jso {
            type = file.type
        }
        val blob = Blob(arrayOf(buffer), options)
        formData.append("utilityBillsExcel", blob, file.name);

        val response = window.fetch(
            "$BackendServerURL/utilityBill/excel?utilityAccountId=$utilityAccountId",
            RequestInit(
                "POST",
                headers = json(
                    "authorization" to "Bearer $accessToken",
                ),
                body = formData
            )
        ).await()

        if (response.status == HttpStatusCode.OK.value.toShort()) {
            val billsImportSummary: BillsImportSummary = Json.decodeFromDynamic(response.json().await())
            return BillsImportResponse(
                success = true,
                billsImportSummary = billsImportSummary
            )
        } else if (response.status == HttpStatusCode.NotAcceptable.value.toShort()) {
            val jsonResponse: JsonResponse = Json.decodeFromDynamic(response.json().await())
            val billsImportResponse = BillsImportResponse(
                success = false,
                errorMessage = jsonResponse.message
            )
            return billsImportResponse
        }
    } catch (e: dynamic) {
        console.log("Exception in uploadBills(): $e")
    }

    return BillsImportResponse(
        success = false,
        errorMessage = "Unknown error"
    )
}
