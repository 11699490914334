package com.ecosave.watch.portal.models.onboarding

import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class MainProductsApiResponse(
    val stripeProductName: String,
    val stripeProductCost: Double,
    val ecosaveWatchProductEnum: EcosaveWatchProductEnum,
    val trialInDays: Int,
    val stripeProductDescription: String
)

@Serializable
data class EcosaveWatchSubscriptionInvoiceDetails(
    val ecosaveWatchProductEnum: EcosaveWatchProductEnum,
    val status: String,
    val trialStart: LocalDateTime?,
    val trialEnd: LocalDateTime?,
    val totalExcludingTax: Float,
    val tax: Float,
    val total: Float,
    val nextInvoiceDate: LocalDateTime?,
)