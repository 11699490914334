package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.common.InternalNavigationLink
import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.energymanagement.FacilitySelectionComponent
import com.ecosave.watch.portal.components.utilityaccounts.UtilityAccountSummaryFilterComponent
import com.ecosave.watch.portal.components.utilityaccounts.UtilityAccountSummaryTableRow
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants.utilityAccountSummaryHeadersList
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.getUtilityAccountsFilterParams
import com.ecosave.watch.portal.helpers.billing.getUtilityServiceProviderName
import com.ecosave.watch.portal.helpers.common.*
import com.ecosave.watch.portal.models.billing.*
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.billing.*
import com.ecosave.watch.portal.useGlobalState
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.KeyboardArrowDown
import mui.icons.material.KeyboardArrowRight
import mui.icons.material.KeyboardArrowUp
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.onChange
import react.router.useNavigate
import web.cssom.ClassName
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px
import web.file.File
import web.html.HTMLInputElement
import web.html.InputType

val UtilityAccounts = FC<Props> {

    val globalState = useGlobalState()
    val (facilityList, facilityListStateSetter) = useState(listOf<FacilityAccountSummary>())
    val navigate = useNavigate()
    var isLoading by useState(false)
    var expandAllRows by useState(false)
    val (filters, filtersStateSetter) = useState(UtilityAccountFiltersDataClass())
    var facilityFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var streetFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var zipcodeFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var cityFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var stateFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var activeFiltersCount by useState(0)
    var helperMessage by useState("")
    var utilityServiceProviderOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var openImportBillsDialog by useState(false)
    var activeOption by useState<AutoCompleteOption>()
    var facilityOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var utilityOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var utilityAccounts by useState<List<UtilityAccountOptionsResponse>>(emptyList())
    var selectedBillsExcel by useState<File>()
    var (selectedAccountDataState, setSelectedAccountDataState) = useState(SelectedAccountDataState())
    val (notificationState, setNotificationState) = useState(NotificationState())
    var isUploadingBills by useState(false)
    var openBillsSubmittedDialog by useState(false)
    var (submittedBillsState, setSubmittedBillsState) = useState(
        BillsImportSummary()
    )

    fun showNotification(message: String, status: NotificationStatus) {
        setNotificationState(
            notificationState.copy(
                status = status,
                message = message,
                visible = true
            )
        )
    }

    fun closeImportBillsDialog() {
        openImportBillsDialog = false
        setSelectedAccountDataState(SelectedAccountDataState())
        selectedBillsExcel = null
        activeOption = null
        utilityOptions = emptyArray()
    }

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.UTILITY_ACCOUNTS.title)

        mainScope.launch {
            isLoading = true

            val result = getUtilityServiceProviders()
            val response = getFiltersValue()
            val facilities = getFacilities()

            utilityServiceProviderOptions = result.map {
                AutoCompleteOption(it.utilityServiceProviderName, it.utilityServiceProviderId.toString())
            }.toTypedArray()
            facilityFilterOptions = response.facilities.map {
                AutoCompleteOption(it.facilityName, it.facilityId.toString())
            }.toTypedArray()
            streetFilterOptions = response.streets.map {
                AutoCompleteOption(it, it)
            }.toTypedArray()
            zipcodeFilterOptions = response.zipcodes.map {
                AutoCompleteOption(it, it)
            }.toTypedArray()
            cityFilterOptions = response.cities.map {
                AutoCompleteOption(it, it)
            }.toTypedArray()
            stateFilterOptions = response.states.map {
                AutoCompleteOption(it, it)
            }.toTypedArray()
            facilityOptions = facilities.map { item -> AutoCompleteOption(item.facilityName, "${item.facilityId}") }.toTypedArray()
            isLoading = false
        }
    }

    useEffect(utilityAccounts) {
        utilityOptions = utilityAccounts.map { item ->
            AutoCompleteOption(
                "${item.utilityType.type} || ${
                    getUtilityServiceProviderName(
                        item.utilityServiceProviderName,
                        item.utilityServiceProviderNameOther
                    )
                } || ${item.accountNumber ?: "NA"}",
                "${item.utilityAccountId}"
            )
        }.toTypedArray()
    }

    useEffect(filters) {
        mainScope.launch {
            isLoading = true
            var params = "?"
            val filterParams = getUtilityAccountsFilterParams(filters)
            params += filterParams.params
            activeFiltersCount = filterParams.counter
            val list = getFacilitiesForAccountSummary(params)
            if (list == null) {
                helperMessage =
                    "There has been an issue fetching facilities and associated utility accounts, please retry."
                facilityListStateSetter(emptyList())
            } else if (list.isEmpty()) {
                helperMessage = "No facilities"
                facilityListStateSetter(emptyList())
            } else {
                facilityListStateSetter(list)
            }

            isLoading = false
        }
    }

    Box {
        className = ClassName("utility-account-summary-container")
        Box {
            sx {
                marginBottom = 10.px
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
            }
            Box {
                Button {
                    variant = ButtonVariant.contained
                    +PageTitles.UTILITY_BILLS.title
                    endIcon = endIcon.also {
                        KeyboardArrowRight()
                    }
                    onClick = {
                        navigate.invoke(PageRoutes.UTILITY_BILLS.route)
                    }
                }
                Button {
                    sx {
                        marginLeft = 10.px
                    }
                    variant = ButtonVariant.contained
                    startIcon = startIcon.also {
                        Add()
                    }
                    +"Import Bills"
                    onClick = {
                        openImportBillsDialog = true
                    }
                }
            }
            UtilityAccountSummaryFilterComponent {
                currentFilterOptions = filters
                currentFilterOptionsStateSetter = filtersStateSetter
                this.facilityFilterOptions = facilityFilterOptions
                this.cityFilterOptions = cityFilterOptions
                this.stateFilterOptions = stateFilterOptions
                this.zipcodeFilterOptions = zipcodeFilterOptions
                this.streetFilterOptions = streetFilterOptions
                filtersCount = activeFiltersCount
            }
        }
        TableContainer {
            className = ClassName("utility-account-summary-table-height")
            Table {
                stickyHeader = true
                TableHead {
                    TableRow {
                        TableCell {
                            Tooltip {
                                title = if (expandAllRows) ReactNode("Collapse All") else ReactNode("Expand All")
                                IconButton {
                                    onClick = {
                                        expandAllRows = !expandAllRows
                                    }
                                    if (expandAllRows) {
                                        KeyboardArrowUp {}
                                    } else {
                                        KeyboardArrowDown {}
                                    }
                                }
                            }
                        }
                        for (header in utilityAccountSummaryHeadersList) {
                            TableHeader {
                                tableHeaderName = header
                            }
                        }
                    }
                }
                TableBody {
                    if (isLoading || facilityList.isEmpty()) {
                        TableCell {
                            colSpan = 5
                            align = TableCellAlign.center
                            if (isLoading) +"Fetching Data..." else {
                                if (helperMessage == "No facilities") {
                                    if (facilityFilterOptions.isNotEmpty()) {
                                        +"No facilities and associated utility accounts found."
                                    } else {
                                        +"No facilities and associated utility accounts found. Register your first facility "
                                        InternalNavigationLink {
                                            to = PageRoutes.FACILITY_MANAGEMENT.route
                                            text = "here."
                                        }
                                    }
                                } else {
                                    +helperMessage
                                }
                            }
                        }
                    } else {
                        for (facilityData in facilityList) {
                            UtilityAccountSummaryTableRow {
                                expandRows = expandAllRows
                                facility = facilityData
                                this.utilityServiceProviderOptions = utilityServiceProviderOptions
                            }
                        }
                    }
                }
            }
        }
    }
    DialogWrapper {
        open = openImportBillsDialog
        dialogTitle = "Import Bills"
        DialogContent {
            Grid {
                sx {
                    paddingTop = 20.px
                }
                container = true
                spacing = responsive(3)
                Grid {
                    item = true
                    Typography {
                        variant = TypographyVariant.h6
                        +"1. Select Target Utility Account"
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    FacilitySelectionComponent {
                        name = "facility"
                        options = facilityOptions
                        label = "Select Facility"
                        onSelect = {
                            mainScope.launch {
                                activeOption = null
                                utilityAccounts = getUtilityAccountsByFacilityId(it.value)
                            }
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    Autocomplete<AutocompleteProps<AutoCompleteOption>> {
                        options = utilityOptions
                        value = activeOption ?: AutoCompleteOption("", "")
                        onChange = { _, _, reason, details ->
                            activeOption = details?.option
                            val utilityAccountId = details?.option?.value?.toInt()
                            setSelectedAccountDataState(
                                selectedAccountDataState.copy(
                                    utilityAccountId = utilityAccountId,
                                    utilityType = utilityAccounts.toMutableList().find { it.utilityAccountId == utilityAccountId }?.utilityType
                                )
                            )
                        }
                        size = "small"
                        renderInput = { params ->
                            TextField.create {
                                label = ReactNode("Select Utility Account")
                                +params
                            }
                        }
                    }
                }
                Grid {
                    item = true
                    Typography {
                        variant = TypographyVariant.h6
                        +"2. Download Excel Template"
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    Button {
                        href = when (selectedAccountDataState.utilityType) {
                            UtilityType.ELECTRIC -> "templates/electric_bill_template.xlsx"
                            UtilityType.NATURAL_GAS -> "templates/natural_gas_bill_template.xlsx"
                            UtilityType.WATER -> "templates/water_bill_template.xlsx"
                            UtilityType.STEAM -> "templates/steam_bill_template.xlsx"
                            UtilityType.HOT_WATER -> "templates/hot_water_bill_template.xlsx"
                            UtilityType.CHILLED_WATER -> "templates/chilled_water_bill_template.xlsx"
                            UtilityType.SOLAR -> "templates/solar_bill_template.xlsx"
                            null -> ""
                        }
                        variant = ButtonVariant.contained
                        disabled = activeOption == null
                        +"Download"
                    }
                }
                Grid {
                    item = true
                    Typography {
                        variant = TypographyVariant.h6
                        +"3. Upload Excel File"
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    Box {
                        sx {
                            display = Display.flex
                            justifyContent = JustifyContent.spaceBetween
                        }
                        TextField {
                            size = Size.small
                            variant = FormControlVariant.standard
                            type = InputType.file
                            InputLabelProps = jso {
                                shrink = true
                            }
                            onChange = {
                                val target = it.target as HTMLInputElement
                                selectedBillsExcel = target.files?.get(0)
                            }
                        }
                        Button {
                            variant = ButtonVariant.contained
                            disabled = isUploadingBills || activeOption == null || selectedBillsExcel == null
                            onClick = {
                                mainScope.launch {
                                    isUploadingBills = true
                                    val response = uploadBills(selectedBillsExcel!!, selectedAccountDataState.utilityAccountId!!)
                                    isUploadingBills = false

                                    if (response.success) {
                                        closeImportBillsDialog()
                                        setSubmittedBillsState(response.billsImportSummary!!)
                                        openBillsSubmittedDialog = true
                                    } else {
                                        val errorMessage = response.errorMessage ?: Constants.NOTIFICATION_ERROR_MESSAGE
                                        showNotification(errorMessage, NotificationStatus.ERROR)
                                    }
                                }
                            }
                            if (isUploadingBills) +"Uploading bills..." else +"Upload"
                        }
                    }
                }
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                variant = ButtonVariant.contained
                +"Cancel"
                onClick = {
                    closeImportBillsDialog()
                }
            }
        }
    }
    DialogWrapper {
        open = openBillsSubmittedDialog
        dialogTitle = "Import Bills Status"
        DialogContent {
            Typography {
                variant = TypographyVariant.h6
                +"Total bills: ${submittedBillsState.totalBillsCount}"
            }
            Typography {
                variant = TypographyVariant.h6
                +"Imported bills: ${submittedBillsState.uploadedBillsCount}"
            }
            Typography {
                variant = TypographyVariant.h6
                +"Duplicate bills: ${submittedBillsState.duplicateBillsCount}"
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    openBillsSubmittedDialog = false
                    setSubmittedBillsState(BillsImportSummary())
                }
                +"Okay"
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}