package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.helpers.Colors
import emotion.react.css
import react.FC
import react.Props
import react.router.dom.Link
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.FontWeight
import web.cssom.TextDecoration

external interface InternalNavigationLinkProps : Props {
    var text: String
    var to: String
}

val InternalNavigationLink = FC<InternalNavigationLinkProps> { props ->
    Link {
        to = props.to
        css {
            color = Color(Colors.PURPLE)
            cursor = Cursor.pointer
            fontWeight = FontWeight.bold
            textDecoration = TextDecoration.solid
            hover {
                textDecoration = TextDecoration.underline
            }
        }
        +props.text
    }
}