package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.facilitymanagement.showEnergyStarSubscriptionStatus
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingConstants.utilityAccountTableHeadersList
import com.ecosave.watch.portal.helpers.billing.UtilityDataCollectionMethod
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.createUtilityAccountRequestBody
import com.ecosave.watch.portal.helpers.billing.getUtilityTypeDescription
import com.ecosave.watch.portal.helpers.billing.isEcosaveWatchAutomaticDataCollectionSubscriptionCancelled
import com.ecosave.watch.portal.helpers.billing.isUtilityAccountFormValid
import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.billing.FacilityAccountSummary
import com.ecosave.watch.portal.models.billing.UtilityAccountData
import com.ecosave.watch.portal.models.billing.UtilityAccountState
import com.ecosave.watch.portal.models.billing.UtilityAccountSummary
import com.ecosave.watch.portal.models.billing.UtilityAccountValidationState
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.billing.createUtilityAccount
import com.ecosave.watch.portal.services.billing.updateUtilityAccount
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Edit
import mui.icons.material.InfoOutlined
import mui.icons.material.KeyboardArrowDown
import mui.icons.material.KeyboardArrowUp
import mui.icons.material.Visibility
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Collapse
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.IconButton
import mui.material.Size
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.span
import react.router.useNavigate
import react.useEffect
import react.useState
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.rem

external interface UtilityAccountSummaryTableRowProps : Props {
    var facility: FacilityAccountSummary
    var expandRows: Boolean
    var utilityServiceProviderOptions: Array<AutoCompleteOption>
}

val UtilityAccountSummaryTableRow = FC<UtilityAccountSummaryTableRowProps> { props ->

    val facilityAccountSummary = props.facility
    val (openHiddenRow, openHiddenRowStateSetter) = useState(props.expandRows)
    val (openAddOrEditUtilityAccountForm, openAddOrEditUtilityAccountFormSetter) = useState(false)
    val (utilityAccountState, setUtilityAccountState) = useState(UtilityAccountState())
    val (utilityAccountValidationState, setUtilityAccountValidationState) = useState(UtilityAccountValidationState())
    val (notificationState, setNotificationState) = useState(NotificationState())
    var addUtilityAccount by useState(true)
    var disableUtilityAccountFields by useState(false)
    var isSubmitting by useState(false)
    var utilityAccountName by useState("")
    var showAdditionalWaterFormField by useState(false)
    var showAdditionalSteamFormFields by useState(false)
    var hideFieldsNotNeededInSolarForm by useState(false)
    var showSolarFormDefaultFields by useState(false)
    var showSolarFormPPAFields by useState(false)
    var showSolarFormMeteredFields by useState(false)
    val (showUtilityInputForm, setShowUtilityInputForm) = useState(false)
    var utilityInputFormToShow by useState(UtilityType.ELECTRIC)
    val navigate = useNavigate()
    val globalState = useGlobalState()
    var utilityAccountSummary by useState<UtilityAccountSummary?>(null)

    useEffect(props.expandRows) {
        openHiddenRowStateSetter(props.expandRows)
    }

    fun refreshStatesWhenAccountPopUpIsClosed() {
        openAddOrEditUtilityAccountFormSetter(false)
        setUtilityAccountState(UtilityAccountState())
        setUtilityAccountValidationState(UtilityAccountValidationState())
        addUtilityAccount = true
        utilityAccountSummary = null
    }

    fun showNotification(message: String, status: NotificationStatus) {
        setNotificationState(
            notificationState.copy(
                status = status,
                message = message,
                visible = true
            )
        )
    }

    TableRow {
        hover = true
        TableCell {
            IconButton {
                onClick = { openHiddenRowStateSetter(!openHiddenRow) }
                if (openHiddenRow) {
                    KeyboardArrowUp {}
                } else {
                    KeyboardArrowDown {}
                }
            }
        }
        TableCell {
            +facilityAccountSummary.facilityName
        }
        TableCell {
            +"${facilityAccountSummary.buildingAddress.street}, ${facilityAccountSummary.buildingAddress.city}, ${facilityAccountSummary.buildingAddress.state} ${facilityAccountSummary.buildingAddress.zipcode}"
        }
        TableCell {
            +"${facilityAccountSummary.buildingTotalArea} sq.ft"
        }
        TableCell {
            showEnergyStarSubscriptionStatus(facilityAccountSummary.energyStarSubscription, facilityAccountSummary.cancellationEffectiveOn)
        }
    }
    TableRow {
        TableCell {
            sx {
                width = 5.pct
                paddingBottom = 0.pct
                paddingTop = 0.pct
            }
        }
        TableCell {
            sx {
                paddingBottom = 0.pct
                paddingTop = 0.pct
            }
            colSpan = 4
            Collapse {
                `in` = openHiddenRow
                Table {
                    TableHead {
                        TableRow {
                            TableCell {
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                sx {
                                    fontWeight = FontWeight.bolder
                                    fontSize = 1.25.rem
                                }
                                +"Accounts"
                                align = TableCellAlign.center
                                colSpan = 9
                            }
                        }
                        TableRow {
                            TableCell {
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                Button {
                                    variant = ButtonVariant.contained
                                    onClick = {
                                        addUtilityAccount = true
                                        openAddOrEditUtilityAccountFormSetter(true)
                                        disableUtilityAccountFields = false
                                        showAdditionalWaterFormField = false
                                        showAdditionalSteamFormFields = false
                                        hideFieldsNotNeededInSolarForm = false
                                        showSolarFormMeteredFields = false
                                        showSolarFormPPAFields = false
                                        showSolarFormDefaultFields = false

                                        setUtilityAccountState(
                                            utilityAccountState.copy(
                                                facilityId = facilityAccountSummary.facilityId,
                                                isActive = true,
                                                solarSystemInUse = true,
                                                coolingSteamUsed = true,
                                                heatingSteamUsed = true
                                            )
                                        )
                                    }
                                    startIcon = startIcon.also {
                                        Add()
                                    }
                                    +"Add Account"
                                }
                            }
                        }
                        TableRow {
                            for (header in utilityAccountTableHeadersList) {
                                TableHeader {
                                    tableHeaderName = header
                                    removeBottomBorder = true
                                }
                            }
                        }
                    }
                    TableBody {
                        if (facilityAccountSummary.utilityAccounts.isEmpty()) {
                            TableCell {
                                colSpan = 9
                                align = TableCellAlign.center
                                className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                +"No utility accounts found, please add accounts."
                            }
                        } else {
                            for (account in facilityAccountSummary.utilityAccounts) {
                                TableRow {
                                    hover = true
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        +getUtilityTypeDescription(account.utilityType.toString())
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        if (account.selectedUtilityServiceProviderName == null) +"NA" else +account.selectedUtilityServiceProviderName
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        if (account.accountNumber == null) +"NA" else +account.accountNumber.toString()
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        +account.serviceAddress
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        if (account.cancellationEffectiveOn != null && !isEcosaveWatchAutomaticDataCollectionSubscriptionCancelled(
                                                account.collectionMethod,
                                                account.cancellationEffectiveOn
                                            )
                                        ) {
                                            +"${account.collectionMethod.description} (Ecosave Watch Automatic Data Collection cancelling on ${account.cancellationEffectiveOn.date})"
                                        } else {
                                            +account.collectionMethod.description
                                        }
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        if (account.collectionStatusDetail == null) {
                                            +account.collectionStatus
                                        } else {
                                            span {
                                                +account.collectionStatus
                                                Tooltip {
                                                    title = ReactNode(account.collectionStatusDetail)
                                                    IconButton {
                                                        size = Size.small
                                                        InfoOutlined()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        if (account.latestBillMonth == null) +"" else +account.latestBillMonth
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        if (account.isActive == null) {
                                            +"NA"
                                        } else if (account.isActive) {
                                            +"Active"
                                        } else {
                                            +"Inactive"
                                        }
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        Tooltip {
                                            title = ReactNode("Edit Account")
                                            IconButton {
                                                onClick = {
                                                    globalState.updateUtilityAccountId(account.utilityAccountId)
                                                    globalState.updateFacilityForUtilityBills(account.facilityId)
                                                    val utilityType = UtilityType.valueOf(account.utilityType.toString())
                                                    globalState.updateUtilityAccountData(
                                                        UtilityAccountData(
                                                            account.utilityAccountId,
                                                            account.accountNumber,
                                                            account.utilityType,
                                                            account.surchargeBillExists ?: false,
                                                            account.solarNetMetered ?: false,
                                                            account.solarPPAInPlace ?: false,
                                                            account.coolingSteamUsed ?: false,
                                                            account.heatingSteamUsed ?: false
                                                        )
                                                    )
                                                    showAdditionalWaterFormField = utilityType == UtilityType.WATER
                                                    showAdditionalSteamFormFields = utilityType == UtilityType.STEAM

                                                    val isSolar = utilityType == UtilityType.SOLAR
                                                    hideFieldsNotNeededInSolarForm = isSolar
                                                    showSolarFormDefaultFields = isSolar

                                                    if (isSolar) {
                                                        showSolarFormMeteredFields = account.solarNetMetered ?: false
                                                        showSolarFormPPAFields = account.solarPPAInPlace ?: false
                                                    } else {
                                                        showSolarFormMeteredFields = false
                                                        showSolarFormPPAFields = false
                                                    }

                                                    utilityAccountSummary = account

                                                    addUtilityAccount = false
                                                    openAddOrEditUtilityAccountFormSetter(true)
                                                    disableUtilityAccountFields = true
                                                    utilityAccountName =
                                                        getUtilityTypeDescription(account.utilityType.toString())

                                                    setUtilityAccountState(
                                                        utilityAccountState.copy(
                                                            facilityId = facilityAccountSummary.facilityId,
                                                            utilityAccountId = account.utilityAccountId,
                                                            utilityType = account.utilityType.name,
                                                            utilityServiceProviderId = account.utilityServiceProviderId?.toString() ?: "",
                                                            serviceName = account.serviceName,
                                                            serviceAddress = account.serviceAddress,
                                                            accountNumber = account.accountNumber ?: "",
                                                            meterNumber = account.meterNumber ?: "",
                                                            isActive = account.isActive,
                                                            surchargeBillExists = account.surchargeBillExists,
                                                            coolingSteamUsed = account.coolingSteamUsed,
                                                            heatingSteamUsed = account.heatingSteamUsed,
                                                            solarTotalInstalledCapacity = account.solarTotalInstalledCapacity,
                                                            solarCapacityUnit = account.solarCapacityUnit?.name ?: "",
                                                            solarTotalAnnualGeneration = account.solarTotalAnnualGeneration,
                                                            solarUsageUnit = account.solarUsageUnit?.name ?: "",
                                                            solarPPAInPlace = account.solarPPAInPlace,
                                                            solarPPAServiceProvider = account.solarPPAServiceProvider ?: "",
                                                            solarServiceStartDate = account.solarServiceStartDate ?: "",
                                                            solarTotalLengthOfPPA = account.solarTotalLengthOfPPA,
                                                            solarNetMetered = account.solarNetMetered,
                                                            solarSystemInUse = account.solarSystemInUse,
                                                            solarCurrentPPARate = account.solarCurrentPPARate,
                                                            collectionMethod = account.collectionMethod.name,
                                                            utilityServiceProviderWebsiteUrl = account.utilityServiceProviderWebsiteUrl ?: "",
                                                            utilityServiceProviderNameOther = account.utilityServiceProviderNameOther ?: "",
                                                            energyStarMeterId = account.energyStarMeterId ?: "",
                                                            userId = account.userId,
                                                            password = account.password,
                                                            historyRequired = account.historyRequired
                                                        )
                                                    )
                                                }
                                                Edit()
                                            }
                                        }
                                    }
                                    TableCell {
                                        className = CommonStyles.REMOVED_CELL_BOTTOM_BORDER.cssClass
                                        if (account.collectionMethod.name == UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY.name) {
                                            Tooltip {
                                                title = ReactNode("Add Bill")
                                                IconButton {
                                                    onClick = {
                                                        globalState.updateUtilityAccountId(account.utilityAccountId)
                                                        globalState.updateFacilityForUtilityBills(account.facilityId)

                                                        globalState.updateUtilityAccountData(
                                                            UtilityAccountData(
                                                                account.utilityAccountId,
                                                                account.accountNumber,
                                                                account.utilityType,
                                                                account.surchargeBillExists ?: false,
                                                                account.solarNetMetered ?: false,
                                                                account.solarPPAInPlace ?: false,
                                                                account.coolingSteamUsed ?: false,
                                                                account.heatingSteamUsed ?: false
                                                            )
                                                        )

                                                        utilityInputFormToShow = account.utilityType
                                                        setShowUtilityInputForm(true)
                                                    }
                                                    Add()
                                                }
                                            }
                                        }
                                        Tooltip {
                                            title = ReactNode("View Bills")
                                            IconButton {
                                                onClick = {
                                                    globalState.updateUtilityAccountId(account.utilityAccountId)
                                                    globalState.updateFacilityForUtilityBills(account.facilityId)
                                                    globalState.updateUtilityType(account.utilityType)
                                                    globalState.updateAccountNumber(account.accountNumber)
                                                    navigate.invoke("/utility-bills")
                                                }
                                                Visibility()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        DialogWrapper {
            dialogMaxWidth = 900
            open = openAddOrEditUtilityAccountForm
            dialogTitle = if (utilityAccountState.utilityType == UtilityType.SOLAR.name) {
                if (addUtilityAccount) "Add Utility Solar Information" else "Edit Utility Solar Information"
            } else {
                if (addUtilityAccount) "Add Utility Account Information" else "Edit $utilityAccountName Account Information"
            }
            DialogContent {

                AddOrEditUtilityAccount {
                    formState = utilityAccountState
                    setFormState = setUtilityAccountState
                    formValidationState = utilityAccountValidationState
                    setFormValidationState = setUtilityAccountValidationState
                    disableFields = disableUtilityAccountFields
                    showAdditionalWaterField = showAdditionalWaterFormField
                    showAdditionalSteamFields = showAdditionalSteamFormFields
                    hideFieldsNotNeededInSolar = hideFieldsNotNeededInSolarForm
                    showSolarDefaultFields = showSolarFormDefaultFields
                    showSolarMeteredFields = showSolarFormMeteredFields
                    showSolarPPAFields = showSolarFormPPAFields
                    energyStarSubscription = facilityAccountSummary.energyStarSubscription
                    this.addUtilityAccount = addUtilityAccount
                    this.utilityServiceProviderOptions = props.utilityServiceProviderOptions
                }

                DataCollectionOptions {
                    formStateProp = utilityAccountState
                    formStateSetterProp = setUtilityAccountState
                    formValidationStateProp = utilityAccountValidationState
                    formValidationStateSetterProp = setUtilityAccountValidationState
                    this.addUtilityAccount = addUtilityAccount
                    this.utilityAccountSummary = utilityAccountSummary
                }

            }
            DialogActions {
                sx {
                    justifyContent = JustifyContent.center
                }
                Button {
                    onClick = {
                        refreshStatesWhenAccountPopUpIsClosed()
                    }
                    +"Cancel"
                }
                Button {
                    variant = ButtonVariant.contained
                    onClick = {
                        if (isUtilityAccountFormValid(
                                utilityAccountState,
                                utilityAccountValidationState,
                                setUtilityAccountValidationState
                            )
                        ) {
                            mainScope.launch {
                                isSubmitting = true

                                val utilityAccount = createUtilityAccountRequestBody(utilityAccountState)

                                val utilityAccountApiResponse = if (addUtilityAccount && utilityAccountState.utilityAccountId == null) {
                                    createUtilityAccount(utilityAccount)
                                } else {
                                    updateUtilityAccount(utilityAccount)
                                }

                                when (utilityAccountApiResponse.statusCode) {
                                    null -> {
                                        showNotification(Constants.NOTIFICATION_ERROR_MESSAGE, NotificationStatus.ERROR)
                                    }

                                    HttpStatusCode.NotFound -> {
                                        showNotification("${BillingConstants.ENERGY_STAR} meter id is not valid.", NotificationStatus.ERROR)
                                    }

                                    HttpStatusCode.Forbidden -> {
                                        showNotification(
                                            "We currently do not have access to this ${BillingConstants.ENERGY_STAR} meter and we also haven't received a share request for it.",
                                            NotificationStatus.ERROR
                                        )
                                    }

                                    HttpStatusCode.ServiceUnavailable -> {
                                        showNotification(
                                            "${BillingConstants.ENERGY_STAR} API is having issues right now. Please try adding meter id later.",
                                            NotificationStatus.ERROR
                                        )
                                    }

                                    else -> {

                                        val accounts = facilityAccountSummary.utilityAccounts.toMutableList()

                                        if (addUtilityAccount && utilityAccountState.utilityAccountId == null) {

                                            // utility account creation
                                            utilityAccountApiResponse.utilityAccountSummary?.let { utilityAccountSummary ->
                                                accounts.add(0, utilityAccountSummary)
                                            }

                                            showNotification("Account Created Successfully.", NotificationStatus.SUCCESS)

                                        } else {
                                            // utility account update
                                            val index = accounts.indexOf(accounts.find {
                                                it.utilityAccountId == utilityAccountApiResponse.utilityAccountSummary?.utilityAccountId
                                            })

                                            accounts[index] = utilityAccountApiResponse.utilityAccountSummary!!

                                            showNotification("Account Updated Successfully.", NotificationStatus.SUCCESS)
                                        }

                                        facilityAccountSummary.utilityAccounts = accounts
                                        refreshStatesWhenAccountPopUpIsClosed()

                                        isSubmitting = false
                                    }
                                }
                                isSubmitting = false
                            }
                        }
                    }
                    disabled = isSubmitting || utilityAccountState.utilityType.isBlank()
                    +if (isSubmitting) "Saving..." else "Save"
                }
            }
        }
    }

    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    DialogWrapper {
        dialogMaxWidth = 1200
        open = showUtilityInputForm
        DialogContent {
            when (utilityInputFormToShow) {
                UtilityType.ELECTRIC -> ElectricForm {
                    setShowInputForm = setShowUtilityInputForm
                    this.showNotification = { status, message ->
                        showNotification(message, status)
                    }
                }

                UtilityType.NATURAL_GAS -> NaturalGasForm {
                    setShowInputForm = setShowUtilityInputForm
                    this.showNotification = { status, message ->
                        showNotification(message, status)
                    }
                }

                UtilityType.STEAM -> SteamForm {
                    setShowInputForm = setShowUtilityInputForm
                    this.showNotification = { status, message ->
                        showNotification(message, status)
                    }
                }

                UtilityType.HOT_WATER -> HotOrChilledWaterForm {
                    setShowInputForm = setShowUtilityInputForm
                    this.showNotification = { status, message ->
                        showNotification(message, status)
                    }
                }

                UtilityType.CHILLED_WATER -> HotOrChilledWaterForm {
                    setShowInputForm = setShowUtilityInputForm
                    this.showNotification = { status, message ->
                        showNotification(message, status)
                    }
                }

                UtilityType.WATER -> WaterForm {
                    setShowInputForm = setShowUtilityInputForm
                    this.showNotification = { status, message ->
                        showNotification(message, status)
                    }
                }

                UtilityType.SOLAR -> SolarForm {
                    setShowInputForm = setShowUtilityInputForm
                    this.showNotification = { status, message ->
                        showNotification(message, status)
                    }
                }
            }
        }
    }
}