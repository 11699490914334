package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.ServicesCost
import com.ecosave.watch.portal.helpers.common.decodeAccessToken
import com.ecosave.watch.portal.helpers.common.setTokensInLocalStorage
import com.ecosave.watch.portal.helpers.common.showNotification
import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import com.ecosave.watch.portal.models.AvailableProduct
import com.ecosave.watch.portal.models.auth.TokenData
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.onboarding.MainProductsApiResponse
import com.ecosave.watch.portal.services.getAccessToken
import com.ecosave.watch.portal.services.getAvailableProducts
import com.ecosave.watch.portal.services.getHttpClient
import com.ecosave.watch.portal.services.httpClient
import com.ecosave.watch.portal.services.onboarding.getMainProducts
import com.ecosave.watch.portal.services.subscribeAvailableProduct
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.useGlobalState
import js.core.jso
import kotlinx.browser.localStorage
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDateTime
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Card
import mui.material.CardActions
import mui.material.CardContent
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ul
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexWrap
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.Padding
import web.cssom.px
import web.cssom.unaryMinus

val AvailableProducts = FC<Props> {

    val globalState = useGlobalState()
    var availableProductsList by useState(emptyList<AvailableProduct>())
    var mainProductsList by useState<List<MainProductsApiResponse>>(emptyList())
    var isLoading by useState(false)

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.AVAILABLE_PRODUCTS.title)
        mainScope.launch {
            isLoading = true
            availableProductsList = getAvailableProducts()
            mainProductsList = getMainProducts()
            isLoading = false
        }
    }

    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.center
            gap = 50.px
            flexWrap = FlexWrap.wrap
        }
        if (isLoading) {
            Box {
                sx {
                    marginTop = 50.px
                }
                +"Fetching Products..."
            }
        } else {
            availableProductsList.forEach { product ->
                AvailableProductCard {
                    this.product = product
                    trialInDays = mainProductsList.find { it.ecosaveWatchProductEnum == product.ecosaveWatchProductEnum }?.trialInDays
                    productCost = mainProductsList.find { it.ecosaveWatchProductEnum == product.ecosaveWatchProductEnum }?.stripeProductCost
                    updateSubscribedProductState = { product ->
                        val updatedList = availableProductsList.toMutableList()
                        updatedList.find { it.ecosaveWatchProductEnum == product }?.subscribed = true
                        availableProductsList = updatedList
                    }
                }
            }
        }
    }
}

external interface AvailableProductCardProps : Props {
    var product: AvailableProduct
    var trialInDays: Int?
    var productCost: Double?
    var updateSubscribedProductState: (EcosaveWatchProductEnum) -> Unit
}

val AvailableProductCard = FC<AvailableProductCardProps> { props ->

    val (notificationState, setNotificationState) = useState(NotificationState())
    var isSubscribing by useState(false)
    val globalState = useGlobalState()
    var openNotificationDialog by useState(false)
    var trialEndDate: LocalDateTime? by useState(null)

    Card {
        sx {
            maxWidth = 500.px
            borderRadius = 20.px
            padding = Padding(10.px, 20.px, 20.px, 20.px)
            color = Color(Colors.GRAY)
            height = Length.fitContent
        }
        CardContent {
            Typography {
                variant = TypographyVariant.h5
                align = TypographyAlign.center
                sx {
                    fontWeight = FontWeight.bold
                    color = Color(Colors.WHITE)
                }
                +props.product.ecosaveWatchProductEnum.description
            }
            Typography {
                sx {
                    marginBottom = 15.px
                }
                align = TypographyAlign.center
                variant = TypographyVariant.body1
                gutterBottom = true
                if (props.trialInDays != 0) {
                    +"${props.trialInDays} days trial"
                }
            }
            when (props.product.ecosaveWatchProductEnum) {
                EcosaveWatchProductEnum.ENERGY_MANAGEMENT_PORTAL -> {
                    energyManagementPortalCardContent(props.productCost)
                }

                EcosaveWatchProductEnum.ESG -> {
                    esgProductCardContent(props.productCost)
                }
            }
        }
        CardActions {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.center
            }
            Button {
                variant = ButtonVariant.contained
                disabled = props.product.subscribed || isSubscribing
                onClick = {
                    mainScope.launch {
                        isSubscribing = true
                        val response = subscribeAvailableProduct(props.product.ecosaveWatchProductEnum)
                        if (response != null) {

                            val tokenInfo: TokenData? = getAccessToken()

                            if (tokenInfo != null) {
                                val userInfo = decodeAccessToken(tokenInfo.accessToken)
                                setTokensInLocalStorage(tokenInfo.accessToken, tokenInfo.refreshToken)
                                httpClient = getHttpClient()

                                globalState.handleUpdateUserData(
                                    globalState.userData.copy(
                                        subscriptions = userInfo.subscriptions
                                    )
                                )

                                props.updateSubscribedProductState(props.product.ecosaveWatchProductEnum)

                                trialEndDate = response.trialEnd
                                openNotificationDialog = true

                            } else {
                                showNotification(
                                    "Subscription has been activated successfully, however an unexpected error has occurred, please sign in again.",
                                    NotificationStatus.SUCCESS,
                                    notificationState,
                                    setNotificationState
                                )
                                delay(5000)
                                localStorage.clear()
                                globalState.updateIsAuthenticated(false)
                            }
                        } else {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.ERROR,
                                    message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                    visible = true
                                )
                            )
                        }
                        isSubscribing = false
                    }
                }
                if (isSubscribing) {
                    +"Activating Subscription..."
                } else {
                    if (props.product.subscribed) {
                        +"Subscription Active"
                    } else if (props.trialInDays == 0 && !props.product.subscribed) {
                        +"Subscribe"
                    } else if (props.trialInDays != 0 && !props.product.subscribed) {
                        +"Start Trial"
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    Dialog {
        open = openNotificationDialog
        PaperProps = jso {
            className = CommonStyles.DIALOG_BORDER_RADIUS.cssClass
        }
        Box {
            className = ClassName("dialog-gradient-strip")
        }
        DialogContent {
            sx {
                marginBottom = -20.px
            }
            Typography {
                variant = TypographyVariant.body1
                sx {
                    marginBottom = 10.px
                }
                +"Subscription has been activated successfully."
            }
            if (props.trialInDays != 0 && trialEndDate != null) {
                Typography {
                    variant = TypographyVariant.body1
                    +"Your trial will end on ${trialEndDate?.date}"
                }
            }
        }
        DialogActions {
            Button {
                +"Okay"
                onClick = {
                    openNotificationDialog = false
                    trialEndDate = null
                }
            }
        }
    }
}

fun ChildrenBuilder.energyManagementPortalCardContent(productCost: Double?) {
    Typography {
        variant = TypographyVariant.body2
        gutterBottom = true
        +"Automate building performance data for nearly real-time insights. Streamline work orders and asset management."
    }
    Typography {
        variant = TypographyVariant.body2
        gutterBottom = true
        +"Unlock Energy performance and reduce net operations expense in your building portfolio."
    }
    Typography {
        variant = TypographyVariant.body1
        sx {
            fontWeight = FontWeight.bold
            marginTop = 15.px
            color = Color(Colors.WHITE)
        }
        +"Key Features"
    }
    ul {
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Realtime data observations to troubleshoot and resolve issues."
            }
        }
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Remote control: log in to the dashboard from any location."
            }
        }
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Process automation: reduce the number of human touchpoints."
            }
        }
    }
    Typography {
        sx {
            fontWeight = FontWeight.bold
            color = Color(Colors.WHITE)
        }
        variant = TypographyVariant.body1
        +"Optional Features"
    }
    ul {
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"For clients preferring aggregated billing data, EcosaveWatch offers Utility Data Acquisition services for a one-time retrieval fee of ${ServicesCost.UTILITY_HISTORY_DATA} and ${ServicesCost.ECOSAVE_WATCH_AUTOMATIC_DATA_COLLECTION} per month, per additional account."
            }
        }
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Energy Star® Portfolio Manager® - ${ServicesCost.ENERGY_STAR} per month, per building (included in your trial)"
            }
        }
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Real-time, automatic updating of your Energy Star Portfolio Manager profile. Visualize how your building ranks in a portfolio or compared to the national average. Helps you comply with your local benchmarking program."
            }
        }
    }
    Typography {
        sx {
            fontWeight = FontWeight.bold
            color = Color(Colors.WHITE)
        }
        align = TypographyAlign.center
        variant = TypographyVariant.h5
        +"$$productCost per month"
    }
}

fun ChildrenBuilder.esgProductCardContent(productCost: Double?) {
    Typography {
        variant = TypographyVariant.body2
        gutterBottom = true
        +"Our Environment Social Governance (ESG) Reporting Service streamlines report creation and distribution using GRI and GRESB-compliant formats."
    }
    Typography {
        variant = TypographyVariant.body1
        sx {
            fontWeight = FontWeight.bold
            marginTop = 15.px
            color = Color(Colors.WHITE)
        }
        +"Key Features"
    }
    ul {
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Ensure compliance with regulatory requirements and industry standards - such as BERDO and Local Law 97."
            }
        }
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Designed to adapt to changing regulatory and stakeholder demands."
            }
        }
        li {
            Typography {
                variant = TypographyVariant.body2
                gutterBottom = true
                +"Showcase your commitment to sustainable and responsible business practices."
            }
        }
    }
    Typography {
        sx {
            fontWeight = FontWeight.bold
            color = Color(Colors.WHITE)
        }
        align = TypographyAlign.center
        variant = TypographyVariant.h5
        +"$$productCost per month"
    }
}


