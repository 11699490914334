package com.ecosave.watch.portal.components.navigation

import com.ecosave.watch.portal.Themes
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.navigation.EnergyManagementLinks
import com.ecosave.watch.portal.helpers.navigation.ReportsLinksNew
import com.ecosave.watch.portal.helpers.navigation.UtilityLinks
import com.ecosave.watch.portal.helpers.navigation.WorkOrderLinks
import com.ecosave.watch.portal.helpers.usermanagement.Subscription
import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import mui.icons.material.AddHome
import mui.icons.material.Bolt
import mui.icons.material.DashboardCustomizeOutlined
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.icons.material.GroupWorkOutlined
import mui.icons.material.Inventory2Outlined
import mui.icons.material.ManageSearchOutlined
import mui.icons.material.NoteAltOutlined
import mui.icons.material.PeopleOutline
import mui.material.Collapse
import mui.material.Drawer
import mui.material.List
import mui.material.ListItemButton
import mui.material.ListItemIcon
import mui.material.ListItemText
import mui.material.ListSubheader
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.html.ReactHTML.img
import react.router.dom.Link
import react.router.useNavigate
import react.useState
import web.cssom.BoxShadow
import web.cssom.Color
import web.cssom.pct
import web.cssom.px
import com.ecosave.watch.portal.styles.NavigationStyles as styles

external interface NavigationDrawerProps : Props {
    var navigationDrawerState: Boolean
    var navigationDrawerStateSetter: StateSetter<Boolean>
}

var NavigationDrawer = FC<NavigationDrawerProps> { props ->

    val navigate = useNavigate()
    val globalState = useGlobalState()
    var utilityInformationOpen by useState(false)
    var workOrderOpen by useState(false)
    var energyManagementOpen by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    Drawer {
        open = props.navigationDrawerState
        onBackdropClick = {
            props.navigationDrawerStateSetter(false)
        }
        List {
            className = styles.NAV_OVERFLOW.cssClass
            sx {
                maxWidth = 360.px
                width = 100.pct
                padding = 0.px
            }
            ListSubheader {
                css {
                    hover {
                        boxShadow = BoxShadow(2.px, 2.px, 5.px, 10.px, Color("rgba(0, 0, 0, 0.2))"))
                    }
                    padding = 0.px
                    margin = 0.px
                }
                sx {
                    backgroundColor = Themes.ecosaveDarkLogoBG
                }
                img {
                    src = "/images/logo/company-logo-dark.jpg"
                    alt = "Ecosave Logo"
                    css {
                        width = 100.pct
                    }
                }
            }
            if (globalState.userData.subscriptions.contains(Subscription.BASE_PORTAL)) {
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        DashboardCustomizeOutlined()
                    }
                    ListItemText {
                        primary = ReactNode(PageTitles.CONTROL_CENTER.title)
                    }
                    onClick = {
                        navigate.invoke("/control-center")
                        props.navigationDrawerStateSetter(false)
                    }
                }
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        Bolt()
                    }
                    ListItemText {
                        primary = ReactNode("Energy Management")
                    }
                    onClick = {
                        energyManagementOpen = !energyManagementOpen
                    }
                    if (energyManagementOpen) ExpandLess() else ExpandMore()
                }
                Collapse {
                    `in` = energyManagementOpen
                    timeout = "auto"
                    List {
                        sx {
                            width = 100.pct
                        }
                        for (energyManagementLink in EnergyManagementLinks.values()) {
                            ListItemButton {
                                className = styles.NAV_LINK.cssClass
                                ListItemIcon {
                                    sx {
                                        paddingLeft = 20.px
                                    }
                                    energyManagementLink.navIcon()
                                }
                                ListItemText {
                                    primary = ReactNode(energyManagementLink.navText)
                                }
                                onClick = {
                                    navigate.invoke(energyManagementLink.linkTo)
                                    props.navigationDrawerStateSetter(false)
                                }
                            }
                        }
                    }
                }
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        GroupWorkOutlined()
                    }
                    ListItemText {
                        primary = ReactNode("Work Orders")
                    }
                    onClick = {
                        workOrderOpen = !workOrderOpen
                    }
                    if (workOrderOpen) ExpandLess() else ExpandMore()
                }
                Collapse {
                    `in` = workOrderOpen
                    timeout = "auto"
                    List {
                        sx {
                            width = 100.pct
                        }
                        for (workOrderLink in WorkOrderLinks.values()) {
                            ListItemButton {
                                className = styles.NAV_LINK.cssClass
                                ListItemIcon {
                                    sx {
                                        paddingLeft = 20.px
                                    }
                                    workOrderLink.navIcon()
                                }
                                ListItemText {
                                    primary = ReactNode(workOrderLink.navText)
                                }
                                onClick = {
                                    navigate.invoke(workOrderLink.linkTo)
                                    props.navigationDrawerStateSetter(false)
                                }
                            }
                        }
                    }
                }
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        NoteAltOutlined()
                    }
                    ListItemText {
                        primary = ReactNode("Utility Information")
                    }
                    onClick = {
                        utilityInformationOpen = !utilityInformationOpen
                    }
                    if (utilityInformationOpen) ExpandLess() else ExpandMore()
                }
                Collapse {
                    `in` = utilityInformationOpen
                    timeout = "auto"
                    List {
                        sx {
                            width = 100.pct
                        }
                        for (utilityLink in UtilityLinks.values()) {
                            Link {
                                className = styles.NAV_LINK.cssClass
                                to = utilityLink.linkTo
                                ListItemButton {
                                    ListItemIcon {
                                        sx {
                                            paddingLeft = 20.px
                                        }
                                        utilityLink.navIcon()
                                    }
                                    ListItemText {
                                        primary = ReactNode(utilityLink.navText)
                                    }
                                    onClick = {
                                        props.navigationDrawerStateSetter(false)
                                    }
                                }
                            }
                        }
                    }
                }
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        AddHome()
                    }
                    ListItemText {
                        primary = ReactNode("Facility Management")
                    }
                    onClick = {
                        navigate.invoke("/facility-management")
                        props.navigationDrawerStateSetter(false)
                    }
                }
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        AddHome()
                    }
                    ListItemText {
                        primary = ReactNode("Facility Group Management")
                    }
                    onClick = {
                        navigate.invoke("/facility-groups")
                        props.navigationDrawerStateSetter(false)
                    }
                }
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        ManageSearchOutlined()
                    }
                    ListItemText {
                        primary = ReactNode(PageTitles.ASSET_MANAGEMENT.title)
                    }
                    onClick = {
                        navigate.invoke("/asset-management")
                        props.navigationDrawerStateSetter(false)
                    }
                }
            }
            if (globalState.userData.subscriptions.contains(Subscription.ADD_ESG)) {
                for (reportsLink in ReportsLinksNew.values()) {
                    ListItemButton {
                        className = styles.NAV_LINK.cssClass
                        ListItemIcon {
                            reportsLink.navIcon()
                        }
                        ListItemText {
                            primary = ReactNode(reportsLink.navText)
                        }
                        onClick = {
                            navigate.invoke(reportsLink.linkTo)
                            props.navigationDrawerStateSetter(false)
                        }
                    }
                }
            }
            if (globalState.userData.userRole == UserRole.ADMIN.name) {
                ListItemButton {
                    className = styles.NAV_LINK.cssClass
                    ListItemIcon {
                        PeopleOutline()
                    }
                    ListItemText {
                        primary = ReactNode("User Management")
                    }
                    onClick = {
                        navigate.invoke("/user-management")
                        props.navigationDrawerStateSetter(false)
                    }
                }
            }
            ListItemButton {
                className = styles.NAV_LINK.cssClass
                ListItemIcon {
                    Inventory2Outlined()
                }
                ListItemText {
                    primary = ReactNode(PageTitles.AVAILABLE_PRODUCTS.title)
                }
                onClick = {
                    navigate.invoke(PageRoutes.AVAILABLE_PRODUCTS.route)
                    props.navigationDrawerStateSetter(false)
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}