package com.ecosave.watch.portal.components.navigation

import com.ecosave.watch.portal.components.privacypolicy.TermsOfServiceConstants.ecosaveSupportEmail
import com.ecosave.watch.portal.helpers.StripeCustomerPortalUrl
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.helpers.privacypolicy.getMailToWithSubject
import com.ecosave.watch.portal.helpers.usermanagement.Subscription
import com.ecosave.watch.portal.models.common.PopoverPos
import com.ecosave.watch.portal.useGlobalState
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import mui.icons.material.AccountCircle
import mui.icons.material.ContactMail
import mui.icons.material.Menu
import mui.icons.material.Settings
import mui.icons.material.Subscriptions
import mui.material.AppBar
import mui.material.AppBarPosition
import mui.material.Box
import mui.material.Divider
import mui.material.IconButton
import mui.material.IconButtonColor
import mui.material.IconButtonEdge
import mui.material.Link
import mui.material.ListItemButton
import mui.material.ListItemIcon
import mui.material.ListItemText
import mui.material.Popover
import mui.material.Size
import mui.material.Toolbar
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.aria.AriaHasPopup
import react.dom.aria.ariaControls
import react.dom.aria.ariaHasPopup
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.useState
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Padding
import web.cssom.number
import web.cssom.px
import web.html.HTMLElement

val AppBarComponent = FC<Props> {

    val (navigationDrawerState, navigationDrawerStateSetter) = useState(false)
    var bAnchorEl: HTMLElement? by useState(null)
    var isOpen = bAnchorEl != null
    val globalState = useGlobalState()
    val navigate = useNavigate()

    AppBar {
        position = AppBarPosition.fixed

        Toolbar {
            IconButton {
                sx { marginRight = 2.px }
                edge = IconButtonEdge.start
                size = Size.large
                color = IconButtonColor.inherit
                ariaLabel = "menu"
                Menu()
                onClick = {
                    navigationDrawerStateSetter(true)
                }
            }

            Box {
                sx {
                    flexGrow = number(1.0)
                }
                Link {
                    sx {
                        cursor = Cursor.pointer
                    }
                    ReactHTML.img {
                        css {
                            marginTop = 15.px
                            marginRight = 20.px
                            marginLeft = 5.px
                            height = 30.px
                        }
                        src = "/images/logo/company-logo.png"
                    }
                    onClick = {
                        navigate.invoke(PageRoutes.DEFAULT.route)
                    }
                }
            }

            Box {
                IconButton {
                    edge = IconButtonEdge.start
                    size = Size.large
                    color = IconButtonColor.inherit
                    ariaLabel = "account of current user"
                    ariaControls = "menu-appbar"
                    ariaHasPopup = AriaHasPopup.`true`
                    AccountCircle()
                    onClick = {
                        bAnchorEl = it.currentTarget
                    }
                }
                Popover {
                    open = isOpen
                    anchorEl = bAnchorEl
                    onClose = { _, _ ->
                        bAnchorEl = null
                    }
                    anchorOrigin = PopoverPos("bottom", "right")
                    transformOrigin = PopoverPos("top", "right")
                    Box {
                        mui.material.List {
                            ListItemText {
                                Box {
                                    sx {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        padding = Padding(0.px, 20.px)
                                    }
                                    Typography {
                                        variant = TypographyVariant.body1
                                        +"${globalState.userData.firstName} ${globalState.userData.lastName}"
                                    }
                                    Typography {
                                        variant = TypographyVariant.body2
                                        +globalState.userData.emailAddress
                                    }
                                }
                            }
                            ListItemButton {
                                ListItemIcon {
                                    sx {
                                        justifyContent = JustifyContent.center
                                    }
                                    AccountCircle()
                                }
                                ListItemText {
                                    +"Profile"
                                }
                                onClick = {
                                    navigate.invoke(PageRoutes.USER_PROFILE.route)
                                    bAnchorEl = null
                                }
                            }
                            if(globalState.userData.subscriptions.contains(Subscription.BASE_PORTAL)) {
                                ListItemButton {
                                    ListItemIcon {
                                        sx {
                                            justifyContent = JustifyContent.center
                                        }
                                        Settings()
                                    }
                                    ListItemText {
                                        +"Preferences"
                                    }
                                    onClick = {
                                        navigate.invoke(PageRoutes.USER_PREFERENCES.route)
                                        bAnchorEl = null
                                    }
                                }
                            }
                            ListItemButton {
                                ListItemIcon {
                                    sx {
                                        justifyContent = JustifyContent.center
                                    }
                                    Subscriptions()
                                }
                                ListItemText {
                                    +"Subscriptions"
                                }
                                onClick = {
                                    window.open(StripeCustomerPortalUrl, "_blank")
                                    bAnchorEl = null
                                }
                            }
                            ListItemButton {
                                ListItemIcon {
                                    sx {
                                        justifyContent = JustifyContent.center
                                    }
                                    ContactMail()
                                }
                                ListItemText {
                                    +"Contact Us"
                                }
                                onClick = {
                                    window.open(ecosaveSupportEmail.getMailToWithSubject(""), "_blank")
                                    bAnchorEl = null
                                }
                            }

                            Divider()
                            ListItemButton {
                                onClick = {
                                    mainScope.launch {
                                        localStorage.clear()
                                        globalState.updateIsAuthenticated(false)
                                    }
                                }
                                +"Sign Out"
                            }
                        }
                    }
                }

            }
        }
    }
    NavigationDrawer {
        this.navigationDrawerState = navigationDrawerState
        this.navigationDrawerStateSetter = navigationDrawerStateSetter
    }
}

