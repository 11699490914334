package com.ecosave.watch.portal.models.common

import kotlinx.serialization.Serializable

@Serializable
data class JsonResponse(
    val timestamp: String,
    val path: String,
    val status: Short,
    val error: String,
    val requestId: String,
    val message: String,
    val trace: String
)
