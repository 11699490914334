package com.ecosave.watch.portal.services.billing

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.models.billing.Facility
import com.ecosave.watch.portal.models.billing.UtilityBill
import com.ecosave.watch.portal.services.httpClient
import com.ecosave.watch.portal.models.billing.utilitybillstate.UtilityBillState
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getFacilities(): List<Facility> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/facility/get-facilities")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }

    } catch (e: dynamic) {
        return emptyList()
    }
    return emptyList()
}

suspend fun createBill(
    utilityBillState: UtilityBillState
): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendServerURL/utilityBill") {
            setBody(utilityBillState)
        }
        if (response.status == HttpStatusCode.Created) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}


suspend fun editUtilityBill(
    utilityBillState: UtilityBillState
): UtilityEditBillApiResponse {
    try {
        val response: HttpResponse = httpClient.put("$BackendServerURL/utilityBill") {
            setBody(utilityBillState)
        }
        if (response.status == HttpStatusCode.OK) {
            return UtilityEditBillApiResponse(
                response.body(),
                ApiCallStatus.SUCCESS
            )
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return UtilityEditBillApiResponse(
        null,
        ApiCallStatus.FAILURE
    )
}

suspend fun deleteUtilityBill(
    utilityBillId: Int
): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendServerURL/utilityBill?utilityBillId=$utilityBillId")
        if (response.status == HttpStatusCode.NoContent) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

data class UtilityEditBillApiResponse(
    val utilityBill: UtilityBill?,
    val apiCallStatus: ApiCallStatus
)
