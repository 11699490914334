package com.ecosave.watch.portal.models

import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import kotlinx.serialization.Serializable

@Serializable
data class AvailableProduct(
    var ecosaveWatchProductEnum: EcosaveWatchProductEnum,
    var subscribed: Boolean
)
