package com.ecosave.watch.portal.components.onboarding

import com.ecosave.watch.portal.components.common.InternalNavigationLink
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.services.getOnboardingStatus
import emotion.react.css
import kotlinx.coroutines.launch
import mui.icons.material.Check
import mui.icons.material.Info
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.AlertVariant
import mui.material.Box
import mui.material.Snackbar
import mui.material.SnackbarCloseReason
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.Display
import web.cssom.Position
import web.cssom.em
import web.cssom.px

val OnboardingHelperNotification = FC<Props> {

    var onboardingStatus by useState<OnBoardingStatus>()
    var openSnackbar by useState(false)

    useEffectOnce {
        mainScope.launch {
            onboardingStatus = getOnboardingStatus()
            openSnackbar = true
        }
    }

    Snackbar {
        open = openSnackbar && onboardingStatus != OnBoardingStatus.DONE
        onClose = { _, reason ->
            if (reason !== SnackbarCloseReason.clickaway)
                openSnackbar = false
        }
        Box {
            Box {
                className = ClassName("onboarding-helper-notification-overlay")
            }
            Alert {
                sx {
                    position = Position.fixed
                    right = 10.px
                    top = 10.px
                    backgroundColor = Color(Colors.DARK_BLUE)
                }
                color = AlertColor.info
                variant = AlertVariant.standard
                icon = Info.create()
                AlertTitle {
                    +"Start visualizing your data by following the steps below."
                }
                ol {
                    css {
                        padding = 0.px
                        margin = 1.em
                    }
                    helperNotificationMessageComponent(
                        PageRoutes.FACILITY_MANAGEMENT,
                        message = "Register your first facility ",
                        showIcon = onboardingStatus != OnBoardingStatus.FACILITY_PENDING
                    )
                    helperNotificationMessageComponent(
                        PageRoutes.UTILITY_ACCOUNTS,
                        message = "Register your first utility account ",
                        showIcon = onboardingStatus != OnBoardingStatus.FACILITY_PENDING && onboardingStatus != OnBoardingStatus.UTILITY_ACCOUNT_PENDING

                    )
                    helperNotificationMessageComponent(
                        PageRoutes.UTILITY_ACCOUNTS,
                        message = "Register your first utility bill ",
                        showIcon = onboardingStatus != OnBoardingStatus.FACILITY_PENDING && onboardingStatus != OnBoardingStatus.UTILITY_ACCOUNT_PENDING && onboardingStatus != OnBoardingStatus.MANUAL_BILL_PENDING
                    )
                }
                onClose = {
                    openSnackbar = false
                }
            }
        }
    }
}

fun ChildrenBuilder.helperNotificationMessageComponent(route: PageRoutes, message: String, showIcon: Boolean) = li {
    css {
        margin = 2.px
    }
    Box {
        sx {
            display = Display.flex
            alignItems = AlignItems.flexEnd
            gap = 5.px
        }
        Box {
            +message
            InternalNavigationLink {
                to = route.route
                text = "here."
            }
        }
        if (showIcon) {
            Check {
                sx {
                    color = Color(Colors.GREEN)
                }
            }
        }
    }
}