package com.ecosave.watch.portal.styles

import web.cssom.ClassName

enum class AboutUsPageStyles(val cssClass: ClassName) {
    MAIN_BOX(ClassName("about-main")),
    CARD_SECTOR(ClassName("about-card-sector")),
    MAIN_TITLE(ClassName("about-information"))
}

enum class CommonStyles(val cssClass: ClassName) {
    FLEX_FILLED(ClassName("filled-flex")),
    BASIC_BACK(ClassName("basic-backspace")),
    LOGO_BOX(ClassName("logo-box")),
    REMOVED_CELL_BOTTOM_BORDER(ClassName("table-cell-without-bottom-border")),
    MESSAGE_WRAPPER(ClassName("message-wrapper")),
    HEADING_TITLE(ClassName("heading-font")),
    SUBTITLE(ClassName("decorated-subtitle")),
    BADGE(ClassName("badge")),
    BOLD_TEXT(ClassName("bold-text")),
    BOX_CENTER_ALIGN_HORIZONTAL(ClassName("box-center-align-horizontal")),
    DIALOG_BORDER_RADIUS(ClassName("dialog-border-radius")),
    BROWSER_RESIZE(ClassName("browser-resize")),
    CURSOR(ClassName("cursor")),
    TABLE_HEIGHT(ClassName("table-height")),
    CENTERED_BUTTON(ClassName("centered-button")),
    CENTER_ITEM(ClassName("center-item")),
    MARGIN_RIGHT_BUTTON(ClassName("margin-right-button")),
    BACKGROUND_GRADIENT(ClassName("ecosave-background-gradient"))
}

enum class SignInPageStyles(val cssClass: ClassName) {
    CENTER(ClassName("signin-center")),
    LINK(ClassName("signin-link")),
}

enum class NavigationStyles(val cssClass: ClassName) {
    NAV_LINK(ClassName("linkStyle")),
    NAV_OVERFLOW(ClassName("navigation-overflow"))
}

enum class ControlCenterPageStyles(val cssClass: ClassName) {
    STACK_CHART_CONTAINER(ClassName("control-center-stack-chart-container")),
    TEXT_CENTER(ClassName("control-center-text-center")),
    CHART_SECTOR_ONE(ClassName("control-center-chart-sector-one")),
    CHART_SECTOR_TWO(ClassName("control-center-chart-sector-two")),
    PERCENT_TILE(ClassName("control-center-percent-tile")),
    TILE_CONTENT(ClassName("control-center-tile-content")),
    BUTTON(ClassName("control-center-action-button")),
    LINE_WRAPPER(ClassName("control-center-total-line-wrapper")),
}

enum class FacilityManagementStyles(val cssClass: ClassName) {
    FACILITY_LOGO_BOX(ClassName("facility-logo-box")),
    TIME_BOX(ClassName("time-box")),
    TIME_BOX_MARKS(ClassName("time-box-marks")),
    FACILITY_TIME_SELECT_WRAPPER(ClassName("facility-time-select-wrapper")),
    FACILITY_TIME_CENTER(ClassName("facility-time-center")),
    FACILITY_TIME_PARENT(ClassName("facility-time-parent")),
    FACILITY_TIME_WRAPPER(ClassName("facility-time-wrapper")),
    TABLE_HEIGHT(ClassName("facility-summary-table-height"))
}

enum class SubscriptionPaymentPageStyles(val cssClass: ClassName) {
    MAIN(ClassName("subscription-payment-main")),
    PAPER(ClassName("subscription-payment-paper")),
    INNER(ClassName("subscription-payment-inner")),
    CONTENT(ClassName("subscription-payment-content"))
}