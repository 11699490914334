package com.ecosave.watch.portal.services

import com.ecosave.watch.portal.helpers.BackendServerURL
import com.ecosave.watch.portal.helpers.esg.EcosaveWatchProductEnum
import com.ecosave.watch.portal.models.AvailableProduct
import com.ecosave.watch.portal.models.onboarding.EcosaveWatchSubscriptionInvoiceDetails
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getAvailableProducts(): List<AvailableProduct> {
    try {
        val response: HttpResponse = httpClient.get("$BackendServerURL/payment/availableProducts")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        return emptyList()
    }
    return emptyList()
}

suspend fun subscribeAvailableProduct(ecosaveWatchProductEnum: EcosaveWatchProductEnum): EcosaveWatchSubscriptionInvoiceDetails? {
    try {
        val response: HttpResponse =
            httpClient.put("$BackendServerURL/payment/subscribe?ecosaveWatchProductEnum=$ecosaveWatchProductEnum")
        return when (response.status) {
            HttpStatusCode.OK -> {
                return response.body()
            }

            else -> {
                return null
            }
        }

    } catch (e: dynamic) {
        console.log(e)
    }
    return null
}