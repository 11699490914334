package com.ecosave.watch.portal.components.utilityaccounts

import com.ecosave.watch.portal.helpers.billing.BillingCommonAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingConstants
import com.ecosave.watch.portal.helpers.billing.BillingConstants.solarCapacityUnits
import com.ecosave.watch.portal.helpers.billing.BillingConstants.utilityTypeList
import com.ecosave.watch.portal.helpers.billing.BillingHeaders
import com.ecosave.watch.portal.helpers.billing.BillingSolarAnnotations
import com.ecosave.watch.portal.helpers.billing.BillingSolarHeaders
import com.ecosave.watch.portal.helpers.billing.BillingWaterAndSewerAnnotations
import com.ecosave.watch.portal.helpers.billing.SteamType
import com.ecosave.watch.portal.helpers.billing.UtilityAccountFields
import com.ecosave.watch.portal.helpers.billing.UtilityAccountTableHeaders
import com.ecosave.watch.portal.helpers.billing.UtilityDataCollectionMethod
import com.ecosave.watch.portal.helpers.billing.UtilityType
import com.ecosave.watch.portal.helpers.billing.resetAddUtilityAccountForm
import com.ecosave.watch.portal.helpers.billing.validateAccountNumber
import com.ecosave.watch.portal.helpers.billing.validateAccountType
import com.ecosave.watch.portal.helpers.billing.validateCurrentPPARateSolar
import com.ecosave.watch.portal.helpers.billing.validateIsSolarNetMetered
import com.ecosave.watch.portal.helpers.billing.validateOtherUtilityServiceProvider
import com.ecosave.watch.portal.helpers.billing.validatePpaInPlaceSolar
import com.ecosave.watch.portal.helpers.billing.validatePpaServiceProviderSolar
import com.ecosave.watch.portal.helpers.billing.validateServiceAddress
import com.ecosave.watch.portal.helpers.billing.validateServiceName
import com.ecosave.watch.portal.helpers.billing.validateSolarUsageUnit
import com.ecosave.watch.portal.helpers.billing.validateSurchargeBillExists
import com.ecosave.watch.portal.helpers.billing.validateTotalAnnualGenerationSolar
import com.ecosave.watch.portal.helpers.billing.validateTotalInstalledSolarCapacity
import com.ecosave.watch.portal.helpers.billing.validateTotalLengthOfPPASolar
import com.ecosave.watch.portal.helpers.billing.validateUtilityServiceProvider
import com.ecosave.watch.portal.helpers.billing.validateUtilityServiceProviderWebsiteLink
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.common.yesNoDropdown
import com.ecosave.watch.portal.helpers.facilitymanagement.FacilityConstants
import com.ecosave.watch.portal.models.billing.UtilityAccountState
import com.ecosave.watch.portal.models.billing.UtilityAccountValidationState
import com.ecosave.watch.portal.styles.UtilityAccountStyles
import com.ecosave.watch.portal.useGlobalState
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.FormControl
import mui.material.FormControlLabel
import mui.material.FormHelperText
import mui.material.FormLabel
import mui.material.Grid
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Radio
import mui.material.RadioGroup
import mui.material.Select
import mui.material.Size
import mui.material.TextField
import mui.material.Tooltip
import mui.material.TooltipPlacement
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.create
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.fieldset
import react.dom.onChange
import react.useState
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

external interface AddOrEditUtilityAccountProps : Props {
    var formState: UtilityAccountState
    var setFormState: StateSetter<UtilityAccountState>
    var formValidationState: UtilityAccountValidationState
    var setFormValidationState: StateSetter<UtilityAccountValidationState>
    var disableFields: Boolean
    var showAdditionalWaterField: Boolean
    var showAdditionalSteamFields: Boolean
    var hideFieldsNotNeededInSolar: Boolean
    var showSolarDefaultFields: Boolean
    var showSolarPPAFields: Boolean
    var showSolarMeteredFields: Boolean
    var energyStarSubscription: Boolean
    var addUtilityAccount: Boolean
    var utilityServiceProviderOptions: Array<AutoCompleteOption>
}

val AddOrEditUtilityAccount = FC<AddOrEditUtilityAccountProps> { props ->

    val globalState = useGlobalState()
    val formState = props.formState
    val setFormState = props.setFormState
    val formValidationState = props.formValidationState
    val setFormValidationState = props.setFormValidationState
    var showAdditionalWaterField by useState(props.showAdditionalWaterField)
    var showAdditionalSteamFields by useState(props.showAdditionalSteamFields)
    var hideFieldsNotNeededInSolar by useState(props.hideFieldsNotNeededInSolar)
    var showSolarDefaultFields by useState(props.showSolarDefaultFields)
    var showSolarPPAFields by useState(props.showSolarPPAFields)
    var showSolarMeteredFields by useState(props.showSolarMeteredFields)

    Grid {
        container = true

        Grid {
            item = true
            xs = 12
            sm = 6
            Box {
                sx {
                    margin = 20.px
                }
                FormControl {
                    fullWidth = true
                    size = Size.medium
                    error = formValidationState.accountTypeErrorState
                    InputLabel {
                        +UtilityAccountFields.ACCOUNT_TYPE.field
                    }
                    disabled = props.disableFields
                    Select {
                        label = ReactNode(UtilityAccountFields.ACCOUNT_TYPE.field)
                        value = formState.utilityType
                        onChange = { event, _ ->
                            val value = event.target.value
                            val utilityType = UtilityType.valueOf(value)

                            showAdditionalWaterField = utilityType == UtilityType.WATER
                            showAdditionalSteamFields = utilityType == UtilityType.STEAM

                            if (utilityType == UtilityType.SOLAR) {
                                hideFieldsNotNeededInSolar = true
                                showSolarDefaultFields = true
                                resetAddUtilityAccountForm(
                                    formState,
                                    setFormState,
                                    value,
                                    null,
                                    true,
                                    null,
                                    null
                                )

                            } else {
                                if (utilityType == UtilityType.STEAM) {
                                    resetAddUtilityAccountForm(formState, setFormState, value, true, null, false, true)
                                } else {
                                    resetAddUtilityAccountForm(formState, setFormState, value, true, null, null, null)
                                }

                                hideFieldsNotNeededInSolar = false
                                showSolarDefaultFields = false
                            }

                            showSolarMeteredFields = false
                            showSolarPPAFields = false

                            setFormValidationState(
                                UtilityAccountValidationState()
                            )
                        }
                        for (utility in utilityTypeList) {
                            MenuItem {
                                value = utility.name
                                +utility.type
                            }
                        }
                        onBlur = {
                            validateAccountType(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                    FormHelperText {
                        +formValidationState.accountTypeErrorMessage
                    }
                }
            }
        }
        Grid {
            item = true
            xs = 12
            sm = 6
            UtilityAccountTextFieldWrapper {
                label = ReactNode(UtilityAccountFields.SERVICE_NAME.field)
                type = InputType.text
                annotation = BillingCommonAnnotations.ACCOUNT_NAME
                value = formState.serviceName
                error = formValidationState.serviceNameErrorState
                helperText = formValidationState.serviceNameErrorMessage
                onChange = {
                    val target = it.target as HTMLInputElement
                    setFormState(
                        formState.copy(
                            serviceName = target.value
                        )
                    )
                }
                onBlur = {
                    validateServiceName(
                        formState,
                        formValidationState,
                        setFormValidationState
                    )
                }
            }
        }
        Grid {
            item = true
            xs = 12
            sm = 6
            UtilityAccountTextFieldWrapper {
                label = ReactNode(UtilityAccountFields.SERVICE_ADDRESS.field)
                type = InputType.text
                value = formState.serviceAddress
                error = formValidationState.serviceAddressErrorState
                helperText = formValidationState.serviceAddressErrorMessage
                onChange = {
                    val target = it.target as HTMLInputElement
                    setFormState(
                        formState.copy(
                            serviceAddress = target.value
                        )
                    )
                }
                onBlur = {
                    validateServiceAddress(
                        formState,
                        formValidationState,
                        setFormValidationState
                    )
                }
            }
        }

        if (!hideFieldsNotNeededInSolar) {
            Grid {
                item = true
                xs = 12
                sm = 6
                Box {
                    sx {
                        margin = 20.px
                    }
                    Tooltip {
                        title = ReactNode(BillingCommonAnnotations.UTILITY_SERVICE_PROVIDER)
                        placement = TooltipPlacement.top
                        Autocomplete<AutocompleteProps<AutoCompleteOption>> {
                            options = props.utilityServiceProviderOptions
                            value = AutoCompleteOption(
                                props.utilityServiceProviderOptions.find { it.value == formState.utilityServiceProviderId }?.label ?: "",
                                formState.utilityServiceProviderId
                            )
                            onChange = { _, _, _, details ->

                                val value = details?.option?.value ?: ""

                                if (value == BillingConstants.OTHER_UTILITY_SERVICE_PROVIDER_ID.toString()) {
                                    setFormState(
                                        formState.copy(
                                            utilityServiceProviderId = value,
                                            collectionMethod = UtilityDataCollectionMethod.MANUAL_UTILITY_DATA_ENTRY.name
                                        )
                                    )

                                } else {
                                    setFormState(
                                        formState.copy(
                                            utilityServiceProviderId = value,
                                            utilityServiceProviderNameOther = ""
                                        )
                                    )
                                    setFormValidationState(
                                        formValidationState.copy(
                                            otherUtilityServiceProviderErrorState = false,
                                            otherUtilityServiceProviderErrorMessage = null
                                        )
                                    )

                                }
                            }
                            renderInput = { params ->
                                TextField.create {
                                    +params
                                    label = ReactNode(UtilityAccountFields.UTILITY_SERVICE_PROVIDER.field)
                                    error = formValidationState.utilityServiceProviderErrorState
                                    helperText = formValidationState.utilityServiceProviderErrorMessage
                                    onBlur = {
                                        validateUtilityServiceProvider(
                                            formState,
                                            formValidationState,
                                            setFormValidationState
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(UtilityAccountFields.UTILITY_SERVICE_PROVIDER_WEBSITE_LINK.field)
                    type = InputType.text
                    value = formState.utilityServiceProviderWebsiteUrl
                    error = formValidationState.utilityServiceProviderWebsiteLinkErrorState
                    helperText = formValidationState.utilityServiceProviderWebsiteLinkErrorMessage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                utilityServiceProviderWebsiteUrl = target.value
                            )
                        )
                    }
                    onBlur = {
                        validateUtilityServiceProviderWebsiteLink(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
            }
            if (formState.utilityServiceProviderId == BillingConstants.OTHER_UTILITY_SERVICE_PROVIDER_ID.toString()) {
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    UtilityAccountTextFieldWrapper {
                        label = ReactNode(UtilityAccountFields.OTHER_UTILITY_SERVICE_PROVIDER.field)
                        type = InputType.text
                        value = formState.utilityServiceProviderNameOther
                        error = formValidationState.otherUtilityServiceProviderErrorState
                        helperText = formValidationState.otherUtilityServiceProviderErrorMessage
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    utilityServiceProviderNameOther = target.value
                                )
                            )
                        }
                        onBlur = {
                            validateOtherUtilityServiceProvider(
                                formState,
                                formValidationState,
                                setFormValidationState
                            )
                        }
                    }
                }
            }
        }


        if (showSolarDefaultFields) {

            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(BillingSolarHeaders.TOTAL_ANNUAL_GENERATION.header)
                    type = InputType.number
                    annotation = BillingSolarAnnotations.TOTAL_ANNUAL_GENERATION
                    value = formState.solarTotalAnnualGeneration
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                solarTotalAnnualGeneration = target.value.toFloatOrNull()
                            )
                        )
                    }
                    error = formValidationState.totalAnnualGenerationSolarErrorState
                    helperText = formValidationState.totalAnnualGenerationSolarErrorMessage
                    onBlur = {
                        validateTotalAnnualGenerationSolar(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                Box {
                    sx {
                        margin = 20.px
                    }
                    FormControl {
                        fullWidth = true
                        InputLabel {
                            +BillingHeaders.USAGE_UNIT.header
                        }
                        size = Size.medium
                        error = formValidationState.solarUsageUnitErrorState
                        Select {
                            label = ReactNode(BillingHeaders.USAGE_UNIT.header)
                            value = formState.solarUsageUnit
                            onChange = { event, _ ->
                                setFormState(
                                    formState.copy(
                                        solarUsageUnit = event.target.value
                                    )
                                )
                            }
                            onBlur = {
                                validateSolarUsageUnit(
                                    formState,
                                    formValidationState,
                                    setFormValidationState
                                )
                            }
                            for (unit in UtilityType.SOLAR.usageUnits) {
                                MenuItem {
                                    value = unit.name
                                    +unit.description
                                }
                            }
                        }
                        FormHelperText {
                            +formValidationState.solarUsageUnitErrorMessage
                        }
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(BillingSolarHeaders.TOTAL_INSTALLED_SOLAR_CAPACITY.header)
                    type = InputType.number
                    annotation = BillingSolarAnnotations.TOTAL_INSTALLED_SOLAR_CAPACITY
                    value = formState.solarTotalInstalledCapacity
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                solarTotalInstalledCapacity = target.value.toFloatOrNull()
                            )
                        )
                    }
                    error = formValidationState.totalInstalledSolarCapacityErrorState
                    helperText = formValidationState.totalInstalledSolarCapacityErrorMessage
                    onBlur = {
                        validateTotalInstalledSolarCapacity(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }

            }
            Grid {
                item = true
                xs = 12
                sm = 6
                Box {
                    sx {
                        margin = 20.px
                    }
                    FormControl {
                        fullWidth = true
                        InputLabel {
                            +UtilityAccountFields.SOLAR_CAPACITY_UNIT.field
                        }
                        size = Size.medium
                        Select {
                            label = ReactNode(UtilityAccountFields.SOLAR_CAPACITY_UNIT.field)
                            value = formState.solarCapacityUnit
                            onChange = { event, _ ->
                                setFormState(
                                    formState.copy(
                                        solarCapacityUnit = event.target.value
                                    )
                                )
                            }
                            for (unit in solarCapacityUnits) {
                                MenuItem {
                                    value = unit.name
                                    +unit.description
                                }
                            }
                        }
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                Box {
                    sx {
                        margin = 20.px
                    }
                    Tooltip {
                        title = ReactNode(BillingSolarAnnotations.SOLAR_NET_METERED)
                        placement = TooltipPlacement.top
                        FormControl {
                            fullWidth = true
                            size = Size.medium
                            error = formValidationState.ppaInPlaceSolarErrorState
                            InputLabel {
                                +BillingSolarHeaders.PPA_IN_PLACE.header
                            }
                            Select {
                                label = ReactNode(BillingSolarHeaders.PPA_IN_PLACE.header)
                                value = formState.solarPPAInPlace
                                onChange = { event, _ ->
                                    val value = event.target.value.toBoolean()
                                    if (value) {
                                        setFormState(
                                            formState.copy(
                                                solarPPAInPlace = value
                                            )
                                        )
                                    } else {
                                        setFormState(
                                            formState.copy(
                                                solarPPAInPlace = value,
                                                solarPPAServiceProvider = "",
                                                solarServiceStartDate = "",
                                                solarTotalLengthOfPPA = null,
                                                solarCurrentPPARate = null
                                            )
                                        )
                                        setFormValidationState(
                                            formValidationState.copy(
                                                ppaServiceProviderSolarErrorState = false,
                                                ppaServiceProviderSolarErrorMessage = null,
                                                totalLengthOfPPASolarErrorState = false,
                                                totalLengthOfPPASolarErrorMessage = null,
                                                currentPPARateSolarErrorState = false,
                                                currentPPARateSolarErrorMessage = null
                                            )
                                        )
                                    }
                                    showSolarPPAFields = value
                                }
                                for (item in yesNoDropdown) {
                                    MenuItem {
                                        value = item.value
                                        +item.key
                                    }
                                }
                                onBlur = {
                                    validatePpaInPlaceSolar(
                                        formState,
                                        formValidationState,
                                        setFormValidationState
                                    )
                                }
                            }
                            FormHelperText {
                                +formValidationState.ppaInPlaceSolarErrorMessage
                            }
                        }
                    }

                }

            }
            Grid {
                item = true
                xs = 12
                sm = 6
                Box {
                    sx {
                        margin = 20.px
                    }
                    FormControl {
                        fullWidth = true
                        size = Size.medium
                        error = formValidationState.isSolarNetMeteredErrorState
                        InputLabel {
                            +BillingSolarHeaders.SOLAR_NET_METERED.header
                        }
                        Select {
                            label = ReactNode(BillingSolarHeaders.SOLAR_NET_METERED.header)
                            value = formState.solarNetMetered
                            onChange = { event, _ ->
                                val value = event.target.value.toBoolean()
                                if (value) {
                                    setFormState(
                                        formState.copy(
                                            solarNetMetered = value
                                        )
                                    )
                                } else {
                                    setFormState(
                                        formState.copy(
                                            solarNetMetered = value,
                                            accountNumber = "",
                                            meterNumber = ""
                                        )
                                    )
                                    setFormValidationState(
                                        formValidationState.copy(
                                            accountNumberErrorState = false,
                                            accountNumberErrorMessage = null
                                        )
                                    )
                                }
                                showSolarMeteredFields = value
                            }
                            for (item in yesNoDropdown) {
                                MenuItem {
                                    value = item.value
                                    +item.key
                                }
                            }
                            onBlur = {
                                validateIsSolarNetMetered(
                                    formState,
                                    formValidationState,
                                    setFormValidationState
                                )
                            }
                        }
                        FormHelperText {
                            +formValidationState.isSolarNetMeteredErrorMessage
                        }
                    }
                }
            }
        }

        if (showSolarPPAFields) {
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(BillingSolarHeaders.PPA_SERVICE_PROVIDER.header)
                    type = InputType.text
                    annotation = BillingSolarAnnotations.PPA_SERVICE_PROVIDER
                    value = formState.solarPPAServiceProvider
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                solarPPAServiceProvider = target.value
                            )
                        )
                    }
                    error = formValidationState.ppaServiceProviderSolarErrorState
                    helperText = formValidationState.ppaServiceProviderSolarErrorMessage
                    onBlur = {
                        validatePpaServiceProviderSolar(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(BillingSolarHeaders.SERVICE_START_DATE.header)
                    type = InputType.date
                    InputLabelProps = js("({shrink: true})")
                    value = formState.solarServiceStartDate
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                solarServiceStartDate = target.value
                            )
                        )
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(BillingSolarHeaders.TOTAL_LENGTH_OF_PPA.header)
                    type = InputType.number
                    value = formState.solarTotalLengthOfPPA
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                solarTotalLengthOfPPA = target.value.toIntOrNull()
                            )
                        )
                    }
                    error = formValidationState.totalLengthOfPPASolarErrorState
                    helperText = formValidationState.totalLengthOfPPASolarErrorMessage
                    onBlur = {
                        validateTotalLengthOfPPASolar(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(UtilityAccountFields.SOLAR_PPA_RATE.field)
                    type = InputType.number
                    value = formState.solarCurrentPPARate
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                solarCurrentPPARate = target.value.toFloatOrNull()
                            )
                        )
                    }
                    error = formValidationState.currentPPARateSolarErrorState
                    helperText = formValidationState.currentPPARateSolarErrorMessage
                    onBlur = {
                        validateCurrentPPARateSolar(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
            }
        }

        if (showSolarMeteredFields || (formState.utilityType != UtilityType.SOLAR.name)) {
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(UtilityAccountFields.ACCOUNT_NUMBER.field)
                    type = InputType.text
                    annotation = BillingCommonAnnotations.ACCOUNT_NUMBER
                    value = formState.accountNumber
                    error = formValidationState.accountNumberErrorState
                    helperText = formValidationState.accountNumberErrorMessage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                accountNumber = target.value
                            )
                        )
                    }
                    onBlur = {
                        validateAccountNumber(
                            formState,
                            formValidationState,
                            setFormValidationState
                        )
                    }
                }
            }
            Grid {
                item = true
                xs = 12
                sm = 6
                UtilityAccountTextFieldWrapper {
                    label = ReactNode(UtilityAccountFields.METER_NUMBER.field)
                    type = InputType.text
                    annotation = BillingCommonAnnotations.METER_NUMBER
                    value = formState.meterNumber
                    error = formValidationState.meterNumberErrorState
                    helperText = formValidationState.meterNumberErrorMessage
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setFormState(
                            formState.copy(
                                meterNumber = target.value
                            )
                        )
                    }
                }

            }
            if (props.energyStarSubscription) {
                Grid {
                    item = true
                    xs = 12
                    sm = 6
                    UtilityAccountTextFieldWrapper {
                        label = ReactNode(UtilityAccountFields.ENERGYSTAR_METER_ID.field)
                        type = InputType.text
                        annotation = BillingCommonAnnotations.ENERGY_STAR_METER_ID
                        value = formState.energyStarMeterId
                        helperText =
                            if (formState.energyStarMeterId.isNullOrBlank()) ReactNode("An ${BillingConstants.ENERGY_STAR} meter id is required to sync your monthly billing data to ${FacilityConstants.ENERGY_STAR_PORTFOLIO_MANAGER}.") else null
                        onChange = {
                            val target = it.target as HTMLInputElement
                            setFormState(
                                formState.copy(
                                    energyStarMeterId = target.value.ifEmpty { null }
                                )
                            )
                        }
                    }
                }
            }
        }

        if (showSolarDefaultFields) {
            Grid {
                item = true
                xs = 12
                sm = 6
                FormControl {
                    sx {
                        paddingLeft = 22.px
                        marginTop = 17.px
                    }
                    component = fieldset
                    FormLabel {
                        +"Is this System In Use? *"
                    }
                    RadioGroup {
                        onChange = { _, value ->
                            setFormState(
                                formState.copy(
                                    solarSystemInUse = value.toBoolean()
                                )
                            )
                        }
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                        }
                        value = formState.solarSystemInUse
                        FormControlLabel {
                            value = true
                            control = Radio.create()
                            label = ReactNode("Yes")
                        }
                        FormControlLabel {
                            value = false
                            control = Radio.create()
                            label = ReactNode("No")
                        }
                    }
                }
            }
        }

        if (showAdditionalWaterField) {
            Grid {
                item = true
                xs = 12
                Box {
                    className = UtilityAccountStyles.UTILITY_ACCOUNT_LABEL.cssClass
                    Typography {
                        component = ReactHTML.h6
                        variant = TypographyVariant.h6
                        +UtilityAccountFields.SEWER_SURCHARGE_BILL.field
                    }
                }
            }

            Grid {
                item = true
                xs = 12
                Box {
                    sx {
                        margin = 20.px
                    }
                    Tooltip {
                        placement = TooltipPlacement.topEnd
                        title = ReactNode(BillingWaterAndSewerAnnotations.SEPARATE_SEWER_SURCHARGE_BILL)
                        FormControl {
                            fullWidth = true
                            size = Size.medium
                            error = formValidationState.surchargeBillExistsErrorState
                            Select {
                                value = formState.surchargeBillExists
                                onChange = { event, _ ->
                                    setFormState(
                                        formState.copy(
                                            surchargeBillExists = event.target.value.toBoolean()
                                        )
                                    )
                                }
                                for (item in yesNoDropdown) {
                                    MenuItem {
                                        value = item.value
                                        +item.key
                                    }
                                }
                                onBlur = {
                                    validateSurchargeBillExists(
                                        formState,
                                        formValidationState,
                                        setFormValidationState
                                    )
                                }
                            }
                            FormHelperText {
                                +formValidationState.surchargeBillExistsErrorMessage
                            }
                        }
                    }
                }
            }
        }

        if (showAdditionalSteamFields) {
            Grid {
                item = true
                xs = 12
                FormControl {
                    sx {
                        margin = 10.px
                        paddingLeft = 10.px
                    }
                    component = fieldset
                    FormLabel {
                        +"Does your facility use steam for:"
                    }
                    RadioGroup {
                        onChange = { _, value ->
                            when (SteamType.valueOf(value)) {
                                SteamType.HEATING -> {
                                    setFormState(
                                        formState.copy(
                                            heatingSteamUsed = true,
                                            coolingSteamUsed = false
                                        )
                                    )
                                }

                                SteamType.COOLING -> {
                                    setFormState(
                                        formState.copy(
                                            coolingSteamUsed = true,
                                            heatingSteamUsed = false
                                        )
                                    )
                                }

                                SteamType.BOTH -> {
                                    setFormState(
                                        formState.copy(
                                            coolingSteamUsed = true,
                                            heatingSteamUsed = true
                                        )
                                    )
                                }
                            }
                        }
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                        }
                        value =
                            if (formState.coolingSteamUsed == true && formState.heatingSteamUsed == true) {
                                SteamType.BOTH
                            } else if (formState.coolingSteamUsed == true) {
                                SteamType.COOLING
                            } else {
                                SteamType.HEATING
                            }
                        FormControlLabel {
                            value = SteamType.HEATING
                            control = Radio.create()
                            label = ReactNode(SteamType.HEATING.description)
                        }
                        FormControlLabel {
                            value = SteamType.COOLING
                            control = Radio.create()
                            label = ReactNode(SteamType.COOLING.description)
                        }
                        FormControlLabel {
                            value = SteamType.BOTH
                            control = Radio.create()
                            label = ReactNode(SteamType.BOTH.description)
                        }
                    }
                }
            }
        }

        if (!hideFieldsNotNeededInSolar) {
            Grid {
                item = true
                xs = 12
                FormControl {
                    sx {
                        margin = 10.px
                        width = 300.px
                        paddingLeft = 10.px
                    }
                    component = fieldset
                    FormLabel {
                        +UtilityAccountTableHeaders.ACCOUNT_STATUS.header
                    }
                    RadioGroup {
                        onChange = { _, value ->
                            setFormState(
                                formState.copy(
                                    isActive = value.toBoolean()
                                )
                            )
                        }
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                        }
                        value = formState.isActive
                        FormControlLabel {
                            value = true
                            control = Radio.create()
                            label = ReactNode("Active")
                        }
                        FormControlLabel {
                            value = false
                            control = Radio.create()
                            label = ReactNode("Inactive")
                        }
                    }
                }
            }
        }
    }
}