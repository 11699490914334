package com.ecosave.watch.portal

import com.ecosave.watch.portal.components.AdminOnlyPages
import com.ecosave.watch.portal.components.BasePortalOnlyPages
import com.ecosave.watch.portal.components.BrowserResizeErrorComponent
import com.ecosave.watch.portal.components.EsgOnlyPages
import com.ecosave.watch.portal.components.MainLayout
import com.ecosave.watch.portal.components.ProtectedPage
import com.ecosave.watch.portal.components.onboarding.ProductsSelectionAndPayment
import com.ecosave.watch.portal.components.recoverpassword.ChangePassword
import com.ecosave.watch.portal.components.usermanagement.RegisterUser
import com.ecosave.watch.portal.components.userprofile.UserPreferencesComponent
import com.ecosave.watch.portal.components.userprofile.UserProfileComponent
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.isPageResponsive
import com.ecosave.watch.portal.helpers.createMainContentRoute
import com.ecosave.watch.portal.helpers.usermanagement.Subscription
import com.ecosave.watch.portal.helpers.usermanagement.UserRole
import com.ecosave.watch.portal.pages.AboutUs
import com.ecosave.watch.portal.pages.AssetManagement
import com.ecosave.watch.portal.pages.AvailableProducts
import com.ecosave.watch.portal.pages.ControlCenter
import com.ecosave.watch.portal.pages.EsgSummaryPage
import com.ecosave.watch.portal.pages.FacilityGroupManagementPage
import com.ecosave.watch.portal.pages.FacilityManagement
import com.ecosave.watch.portal.pages.FacilityOverviewPage
import com.ecosave.watch.portal.pages.PageNotFound
import com.ecosave.watch.portal.pages.PortfolioOverviewPage
import com.ecosave.watch.portal.pages.PrivacyPolicyPage
import com.ecosave.watch.portal.pages.RecoverPasswordPage
import com.ecosave.watch.portal.pages.RegisterCompany
import com.ecosave.watch.portal.pages.ResourceCenterPage
import com.ecosave.watch.portal.pages.SignIn
import com.ecosave.watch.portal.pages.TermsOfServicePage
import com.ecosave.watch.portal.pages.UserManagement
import com.ecosave.watch.portal.pages.UtilityAccounts
import com.ecosave.watch.portal.pages.UtilityBills
import com.ecosave.watch.portal.pages.WorkOrderActionManagementPage
import com.ecosave.watch.portal.pages.WorkOrderObservationSummaryPage
import js.core.jso
import kotlinx.browser.window
import react.FC
import react.Props
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import react.useEffect
import react.useState

val AppRoutes = FC<Props> {
    var browserWidth by useState(3000)
    val globalState = useGlobalState()

    useEffect {
        window.addEventListener("resize", {
            browserWidth = window.innerWidth
        }, null)
    }

    BrowserResizeErrorComponent {
        visible = isPageResponsive(browserWidth)
        this.browserWidth = browserWidth
    }

    val adminUserLayout = arrayOf(
        jso {
            Component = AdminOnlyPages
            children = arrayOf(
                createMainContentRoute(PageRoutes.USER_MANAGEMENT.route, UserManagement)
            )
        },
        jso {
            Component = EsgOnlyPages
            children = arrayOf(
                createMainContentRoute(PageRoutes.ESG_REPORTING.route, EsgSummaryPage),
            )
        },
        jso {
            Component = BasePortalOnlyPages
            children = arrayOf(
                createMainContentRoute(PageRoutes.CONTROL_CENTER.route, ControlCenter),
                createMainContentRoute(PageRoutes.OBSERVATION_SUMMARY.route, WorkOrderObservationSummaryPage),
                createMainContentRoute(PageRoutes.ACTION_MANAGEMENT.route, WorkOrderActionManagementPage),
                createMainContentRoute(PageRoutes.UTILITY_BILLS.route, UtilityBills),
                createMainContentRoute(PageRoutes.FACILITY_OVERVIEW.route, FacilityOverviewPage),
                createMainContentRoute(PageRoutes.PORTFOLIO_OVERVIEW.route, PortfolioOverviewPage),
                createMainContentRoute(PageRoutes.FACILITY_GROUP_MANAGEMENT.route, FacilityGroupManagementPage),
                createMainContentRoute(PageRoutes.ASSET_MANAGEMENT.route, AssetManagement),
                createMainContentRoute(PageRoutes.UTILITY_ACCOUNTS.route, UtilityAccounts),
                createMainContentRoute(PageRoutes.FACILITY_MANAGEMENT.route, FacilityManagement),
                createMainContentRoute(PageRoutes.USER_PREFERENCES.route, UserPreferencesComponent)
            )
        },
        jso {
            index = true
            Component = if (globalState.userData.subscriptions.size == 1 && globalState.userData.subscriptions.contains(Subscription.ADD_ESG)) {
                EsgSummaryPage
            } else {
                ControlCenter
            }
        },
        createMainContentRoute(PageRoutes.USER_PROFILE.route, UserProfileComponent),
        createMainContentRoute(PageRoutes.AVAILABLE_PRODUCTS.route, AvailableProducts),
        createMainContentRoute(PageRoutes.RESOURCE_CENTER.route, ResourceCenterPage),
        createMainContentRoute(PageRoutes.ABOUT_US.route, AboutUs),
    )

    RouterProvider {
        router = createBrowserRouter(
            routes = arrayOf(
                jso {
                    path = "/"
                    Component = ProtectedPage
                    children = arrayOf(
                        jso {
                            Component = MainLayout
                            children = when (globalState.userData.userRole) {
                                UserRole.ADMIN.name -> adminUserLayout
//                                To be Implemented if we have different routes for user
//                                UserRole.USER.name -> onlyUserLayout
                                else -> adminUserLayout
                            }
                        },
                        createMainContentRoute(PageRoutes.SUBSCRIPTION_PAYMENT.route, ProductsSelectionAndPayment),
                    )
                },
                jso {
                    path = PageRoutes.SIGN_IN.route
                    Component = SignIn
                },
                jso {
                    path = "/legal/terms-of-service"
                    Component = TermsOfServicePage
                },
                jso {
                    path = "/legal/privacy-policy"
                    Component = PrivacyPolicyPage
                },
                jso {
                    path = "/register"
                    Component = RegisterCompany
                },
                jso {
                    path = "/registration/:token"
                    Component = RegisterUser
                },
                jso {
                    path = "/recover-password"
                    Component = RecoverPasswordPage
                },
                jso {
                    path = "/change-password/:token"
                    Component = ChangePassword
                },
                jso {
                    path = "*"
                    Component = PageNotFound
                }
            )
        )
    }
}

