package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.helpers.Colors
import emotion.react.css
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.useNavigate
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import web.cssom.vw

val PageNotFound = FC<Props> {

    val navigate = useNavigate()

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            gap = 10.px
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            height = 80.pct
            margin = 20.px
        }
        Typography {
            sx {
                fontSize = 15.vw
            }
            className = CommonStyles.HEADING_TITLE.cssClass
            variant = TypographyVariant.h1
            +"404"
        }
        Typography {
            variant = TypographyVariant.h2
            component = ReactHTML.h2
            sx {
                fontSize = 3.vw
                fontWeight = FontWeight.bold
                color = Color(Colors.PINKISH_RED)
            }
            +"Page Not Found"
        }
        Typography {
            variant = TypographyVariant.h6
            component = ReactHTML.h6
            css {
                color = Color(Colors.BRIGHT_CYAN_BLUE)
            }
            sx {
                fontSize = 2.vw
            }
            +"Sorry, we can't find the page you are looking for."
        }
        Button {
            variant = ButtonVariant.contained
            onClick = {
                navigate("/")
            }
            sx {
                backgroundColor = Color(Colors.BRIGHT_CYAN_BLUE)
                borderRadius = 8.px
            }
            +"Go Back"
        }
    }
}